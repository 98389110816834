import React from 'react';
import { translate as t } from '@fiverr-private/i18n-react';
import { ConfirmModal } from '../../ConfirmModal';

const T_PREFIX = 'ai_brief.edit_brief.cancellation_modal';

interface CancellationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
}
export const CancellationModal = ({ isOpen, onClose, onSubmit }: CancellationModalProps) => (
    <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        title={t(`${T_PREFIX}.title`)}
        description={t(`${T_PREFIX}.body`)}
        submitText={t(`${T_PREFIX}.discard`)}
        cancelText={t(`${T_PREFIX}.cancel`)}
    />
);
