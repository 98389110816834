import React from 'react';
import { useQuery } from 'react-query';
import { I18n } from '@fiverr-private/i18n-react';
import { Link, Text } from '@fiverr-private/typography';
import { Stack } from '@fiverr-private/layout_components';
import { Stepper } from '@fiverr-private/orca';
import { STEPS_IDS } from '../../common/constants';
import { containerPaddingXByMdBreakpoint } from '../common/containerStylePaddingX';
import { BriefItem } from '../../types';
import { briefTemplatesQueryKey } from '../../common/queryClient';
import { Layout } from '../common/Layout';
import { useBriefContext } from '../../common/BriefContext';
import { ContactSellerButton } from '../common/ContactSellerButton';
import { GenerateButton } from './GenerateButton';
import { InputPrompt } from './InputPrompt';
import { Disclaimer } from './Disclaimer';

const T_PREFIX = 'ai_brief.prompt_step';

const Footer = ({ onGoBack }: { onGoBack?: () => void }) => {
    const { shouldShowContactSellerButton } = useBriefContext();

    if (shouldShowContactSellerButton) {
        return (
            <Stack
                width="100%"
                direction="column"
                gap="4"
                alignItems="center"
                paddingX="6"
                paddingBottom="6"
                paddingTop="2"
            >
                <GenerateButton fullWidth />
                <ContactSellerButton withSeparator />
            </Stack>
        );
    }

    return (
        <Stack justifyContent="spaceBetween" width="100%">
            {onGoBack ? (
                <Link fontSize="b_sm" cursor="pointer" onClick={onGoBack}>
                    <I18n k={`${T_PREFIX}.back`} />
                </Link>
            ) : (
                <span />
            )}
            <GenerateButton />
        </Stack>
    );
};

export const GenerateStep = () => {
    const { navigateById } = Stepper.useContext();
    const { shouldShowContactSellerButton } = useBriefContext();
    const { data: briefTemplates } = useQuery<BriefItem[]>(briefTemplatesQueryKey, { enabled: false });
    const goBackToHomeScreen = () => {
        navigateById(STEPS_IDS.home);
    };

    const onGoBack = briefTemplates?.length && shouldShowContactSellerButton ? goBackToHomeScreen : undefined;

    return (
        <Layout
            withDividerUnderSubTitle
            withTitle
            withDivider={!shouldShowContactSellerButton}
            goBack={onGoBack}
            containerPaddingXByBreakpoint={containerPaddingXByMdBreakpoint}
            content={
                <Stack
                    paddingTop="4"
                    paddingBottom={shouldShowContactSellerButton ? '0' : '4'}
                    gap="4"
                    direction="column"
                    height="100%"
                >
                    <Disclaimer />
                    <Text color="bodyPrimary" fontWeight="semibold" size="b_md">
                        <I18n k={`${T_PREFIX}.describe_your_need`} />
                    </Text>
                    <InputPrompt />
                </Stack>
            }
            buttons={!shouldShowContactSellerButton ? <Footer onGoBack={onGoBack} /> : undefined}
            footer={shouldShowContactSellerButton ? <Footer /> : undefined}
        />
    );
};
