import { useBriefContext } from '../../../common/BriefContext';

export const useIncludeSection = () => {
    const { isBriefInEditMode, updateEditedBrief } = useBriefContext();

    return (section) => {
        if (!isBriefInEditMode) {
            updateEditedBrief(null, section.title, { included: true, isMovedFromExcluded: true });
            section.onAdd?.(section);
        }
    };
};
