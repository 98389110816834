import React from 'react';
import { BinIcon } from '@fiverr-private/icons';
import { translate as t } from '@fiverr-private/i18n-react';
import { IconButton } from '@fiverr-private/button';
import { ConfirmModal } from '../../../ConfirmModal';
import { useBriefDelete } from '../../useBriefDelete';

export interface DeleteBriefButtonProps {
    briefId: string;
    isDisabled?: boolean;
}

const TRANSLATION_PREFIX = 'ai_brief.delete_modal';

export const DeleteBriefButton = ({ briefId, isDisabled }: DeleteBriefButtonProps) => {
    const { deleteBrief, isDeleting } = useBriefDelete(briefId);
    return (
        <ConfirmModal
            title={t(`${TRANSLATION_PREFIX}.title`)}
            description={t(`${TRANSLATION_PREFIX}.description`)}
            submitText={t(`${TRANSLATION_PREFIX}.submit`)}
            cancelText={t(`${TRANSLATION_PREFIX}.cancel`)}
            onSubmit={deleteBrief}
        >
            <IconButton
                variant="ghost"
                size={'sm'}
                intent="secondary"
                shape="square"
                aria-label="delete"
                loading={isDeleting}
                disabled={isDeleting || isDisabled}
            >
                {!isDeleting ? <BinIcon /> : null}
            </IconButton>
        </ConfirmModal>
    );
};
