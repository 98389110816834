import pathfinder from '@fiverr-private/pathfinder';
import { BriefItem } from '../types';
import { BriefTemplatesResponseDTO } from './types';
import { safeRequest } from './safeRequest';

export const BRIEF_TEMPLATE_URL = pathfinder('get_productized_brief_templates');

export const convertBriefTemplatesResponseDtoToBriefTemplates = (res?: BriefTemplatesResponseDTO): BriefItem[] =>
    res?.templates.map((template) => {
        const { brief_title, template_id, updated_by_name, update_at } = template;
        return {
            title: brief_title,
            id: template_id,
            createDate: new Date(update_at * 1000),
            userDisplayName: updated_by_name,
        };
    }) ?? [];

export const getBriefTemplates = (): Promise<BriefItem[]> =>
    safeRequest<BriefTemplatesResponseDTO>('get', BRIEF_TEMPLATE_URL).then((res) =>
        convertBriefTemplatesResponseDtoToBriefTemplates(res)
    );
