import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Stack } from '@fiverr-private/layout_components';
import { Stepper } from '@fiverr-private/orca';
import { logger } from '@fiverr-private/obs';
import { STEPS_IDS } from '../../common/constants';
import { containerPaddingXBySmBreakpoint } from '../common/containerStylePaddingX.ve.css';
import { Layout } from '../common/Layout';
import { Brief } from '../../types';
import { briefResponseQueryKey } from '../../common/queryClient';
import { useBriefContext } from '../../common/BriefContext';
import { bqEvents } from '../../monitoring/bqEvents';
import * as mixpanelEvents from '../../monitoring/mixpanel';
import { ExcludedSectionsNavBar, ExcludedSectionsSidebar } from './ExcludedSections';
import { IncludedSections } from './IncludedSections';
import { EditBriefSendButton, EditBriefBackButton } from './EditBriefButtons';
import { goBackClassName } from './style';
import { useBriefActions } from './useBriefActions';

interface EditBriefProps {
    sellerId: number;
}

export const EditBrief = ({ sellerId }: EditBriefProps) => {
    const { navigateById, currentStepId: step } = Stepper.useContext();
    const { editedBrief, setEditedBrief, sendBigQueryEvent, triggerDrawerExitOrBack, hasBriefTemplateChanged } =
        useBriefContext();
    const { data: briefResponse } = useQuery<Brief, string>(briefResponseQueryKey, { enabled: false });
    const { saveAndSendBrief, isSendingBrief } = useBriefActions();
    const queryClient = useQueryClient();
    const isNewBrief = !editedBrief?.id;
    const currentStepId = step as STEPS_IDS;

    useEffect(() => {
        setEditedBrief(briefResponse as Brief);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [briefResponse]);

    if (!briefResponse || !editedBrief?.sections) {
        return null;
    }

    const onSubmit = async () => {
        sendBigQueryEvent(bqEvents.professional_brief_clicked_on_send_brief());
        if (isNewBrief) {
            mixpanelEvents.clickOnSendBrief({ type: 'new brief' });
            try {
                await saveAndSendBrief({ sellerId });
            } catch {
                logger.error('Failed to create new brief');
                return;
            }
        }

        navigateById(STEPS_IDS.done);
    };

    const goBack = () => {
        const prevScreen: STEPS_IDS = editedBrief.id ? STEPS_IDS.home : STEPS_IDS.generate;
        setEditedBrief(null);
        queryClient.removeQueries(briefResponseQueryKey);

        navigateById(prevScreen);
    };

    const goBackHandler = hasBriefTemplateChanged
        ? () => triggerDrawerExitOrBack({ type: 'back', currentStepId })
        : goBack;

    return (
        <Layout
            {...(hasBriefTemplateChanged
                ? { onClose: () => triggerDrawerExitOrBack({ type: 'exit', currentStepId }) }
                : {})}
            goBackClassName={goBackClassName}
            goBack={goBackHandler}
            containerPaddingXByBreakpoint={containerPaddingXBySmBreakpoint}
            withDividerUnderSubTitle={false}
            withTitle
            applyContainerPaddingOnContent={false}
            content={
                <Stack direction="row" height="100%" width="100%" overflowY="hidden" gap="0">
                    <ExcludedSectionsSidebar />
                    <IncludedSections />
                </Stack>
            }
            footer={<ExcludedSectionsNavBar />}
            buttons={
                <>
                    <EditBriefBackButton onClick={goBackHandler} />
                    <EditBriefSendButton onSubmit={onSubmit} isLoading={isSendingBrief} />
                </>
            }
        />
    );
};
