import React, { createRef, useEffect } from 'react';
import { noop } from 'lodash';
import { Text } from '@fiverr-private/typography';
import { Form, FormControl, FormCounter, FormElement, Textarea, useForm } from '@fiverr-private/forms';
import { autoResizeTextareaHeight } from '../../../../../../common/util';
import { BriefSectionContentProps, BriefSectionViewContentProps } from '../index';

const TEXTAREA_INPUT_LIMIT = 1000;

interface TextareaEditProps extends Pick<BriefSectionContentProps, 'section' | 'onChange'> {
    hideCounter?: boolean;
}

export const TextareaEdit = ({ section, onChange, hideCounter }: TextareaEditProps) => {
    const onContentChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value);
    const inputRef = createRef<HTMLInputElement>();
    useEffect(() => {
        if (!inputRef?.current) {
            return;
        }

        // When EditArea opened then focus on the textarea element
        inputRef.current.setSelectionRange?.(inputRef.current.value.length, inputRef.current.value.length);
        inputRef.current.focus();
        inputRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
        autoResizeTextareaHeight(inputRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const methods = useForm({
        defaultValues: {
            [section.title]: section.content,
        },
    });

    return (
        <Form methods={methods} onSubmit={noop}>
            <FormControl maxLength={TEXTAREA_INPUT_LIMIT} name={section.title}>
                <FormElement ref={inputRef}>
                    <Textarea
                        defaultValue={section.content}
                        onChange={(e) => {
                            onContentChange(e);
                            autoResizeTextareaHeight(inputRef.current);
                        }}
                        maxLength={TEXTAREA_INPUT_LIMIT}
                        overflowY="hidden"
                        autoFocus
                    />
                </FormElement>
                {!hideCounter && <FormCounter />}
            </FormControl>
        </Form>
    );
};

export const TextareaView = ({ section }: BriefSectionViewContentProps) => (
    <Text size="b_md" style={{ whiteSpace: 'pre-line' }}>
        {section.content}
    </Text>
);
