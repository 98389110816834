export const ALLOCATIONS = {
    CONTROL: '1',
    TEST_B: '2',
    TEST_C: '3',
    TEST_D: '4',
} as const;

export const SIZES = {
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
} as const;
