import { noop } from 'lodash';
import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { translate as t } from '@fiverr-private/i18n-react';
import { IconButton } from '@fiverr-private/button';
import { grey_400, grey_600 } from '@fiverr-private/sass/helpers';
import { CloseIcon } from '@fiverr-private/icons';
import { ConfirmModal } from '../../../../../../ConfirmModal';

const DELETE_MODAL_TRANSLATION_KEY = 'ai_brief.upload_area.delete_attachment_modal';

export const DeleteButton = ({ onDelete = noop }: { onDelete: () => void }) => (
    <Container position="absolute" top="0px" right="0px" as="span">
        <ConfirmModal
            onSubmit={onDelete}
            title={t(`${DELETE_MODAL_TRANSLATION_KEY}.title`)}
            description={t(`${DELETE_MODAL_TRANSLATION_KEY}.description`)}
            submitText={t(`${DELETE_MODAL_TRANSLATION_KEY}.submit`)}
            cancelText={t(`${DELETE_MODAL_TRANSLATION_KEY}.cancel`)}
        >
            <IconButton
                aria-label="delete"
                size="xs"
                shape="circle"
                background={{ hover: `${grey_400}66`, default: 'none' }}
            >
                <CloseIcon size={12} color={grey_600} />
            </IconButton>
        </ConfirmModal>
    </Container>
);
