import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { logger } from '@fiverr-private/obs';
import { Stepper } from '@fiverr-private/orca';
import { Brief } from '../../types';
import { briefResponseQueryKey } from '../../common/queryClient';
import { getExistBriefTemplate } from '../../services';
import { STEPS_IDS } from '../../common/constants';

export const useBriefEdit = (id: string) => {
    const queryClient = useQueryClient();

    // Workaround to fetch not using brief ids in query param
    // Use local state as an indicator for data loading, to make it differentiate between specific cards
    const [isLoadingBriefData, setIsLoadingBriefData] = useState(false);

    const { navigateById } = Stepper.useContext();
    const { isLoading: isLoadingBriefResponse, error: briefResponseError } = useQuery<Brief, string>(
        briefResponseQueryKey,
        { enabled: false }
    );

    const setBriefInEdit = async (): Promise<void> => {
        try {
            setIsLoadingBriefData(true);
            const data = await queryClient.fetchQuery<Brief | undefined>({
                queryKey: briefResponseQueryKey,
                queryFn: () => getExistBriefTemplate(id),
            });
            if (data) {
                navigateById(STEPS_IDS.editBrief);
            } else {
                logger.error(new Error('Failed while rendering the Brief-AI HomeScreen and loading brief template'), {
                    cause: briefResponseError,
                });
            }
        } catch (error) {
            logger.error(new Error('Failed while rendering the Brief-AI HomeScreen and loading brief template'), {
                cause: error,
            });
        } finally {
            setIsLoadingBriefData(false);
        }
    };
    return {
        setBriefInEdit,
        isLoadingBriefResponse,
        isLoadingBriefData,
    };
};
