import React from 'react';
import { IconButton } from '@fiverr-private/button';
import { CloseIcon } from '@fiverr-private/icons';
import { grey_1000 } from '@fiverr-private/sass/helpers';

interface ModalCloseBtnProps {
    onClose: () => void;
}
export const ModalCloseBtn = ({ onClose }: ModalCloseBtnProps) => (
    <IconButton onClick={onClose} aria-label="close" variant="ghost" size="xs">
        <CloseIcon size={12} color={grey_1000} />
    </IconButton>
);
