import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { NodeGroup, EASING } from '@fiverr-private/transition';
import { useBriefContext } from '../../../common/BriefContext';
import { containerPaddingXBySmBreakpoint } from '../../common/containerStylePaddingX.ve.css';
import { getNearestOpacity } from '../util';
import { BriefTitle } from './BriefTitle';
import { Section } from './BriefSection';

const STARTING_DELAY = 300;

export const IncludedSections = () => {
    const { editedBrief } = useBriefContext();

    return editedBrief ? (
        <NodeGroup
            data={editedBrief?.sections || []}
            keyAccessor={(d) => d.title}
            start={() => ({ opacity: [0] })}
            enter={(_, index) => ({
                opacity: [1],
                timing: {
                    duration: 300,
                    delay: index * 50 + STARTING_DELAY,
                    ease: EASING.easeIn,
                },
            })}
            leave={() => ({
                opacity: [0],
                timing: {
                    duration: 200,
                    ease: EASING.easeIn,
                },
            })}
        >
            {(nodes) => (
                <Stack
                    className={containerPaddingXBySmBreakpoint}
                    paddingLeft={{ sm: '0', default: '6' }}
                    direction="column"
                    gap="3"
                    paddingY="8"
                    overflowY="auto"
                    overflowX="hidden"
                    width="100%"
                    flex="1 1 auto"
                >
                    <BriefTitle />
                    {nodes.map(({ key, state: { opacity }, data }) => (
                        <Section key={key} opacity={getNearestOpacity(opacity)} section={data} />
                    ))}
                </Stack>
            )}
        </NodeGroup>
    ) : null;
};
