import React from 'react';
import classnames from 'classnames';
import { Card, Stack, Container } from '@fiverr-private/layout_components';
import { BriefIcon, PencilIcon } from '@fiverr-private/icons';
import { IconButton } from '@fiverr-private/button';
import * as styles from '../styles.ve.css';
import { BriefItem } from '../../../types';
import { useBriefEdit } from '../useBriefEdit';
import { CardText } from '../CardText';
import { useBriefContext } from '../../../common/BriefContext';
import { bqEvents } from '../../../monitoring/bqEvents';
import * as mixpanelEvents from '../../../monitoring/mixpanel';
import { formatBriefCardSubtitle } from './utils';
import { DeleteBriefButton } from './DeleteBriefButton';

interface IconsSectionProps {
    briefId: string;
    isLoading?: boolean;
}
const BriefActions = ({ briefId, isLoading = false }: IconsSectionProps) => {
    const { setBriefInEdit, isLoadingBriefData } = useBriefEdit(briefId);
    const isLoadingData = isLoading || isLoadingBriefData;
    const stopPropagation = (event: React.MouseEvent<HTMLSpanElement>) => {
        // Workaround to a global onClick, that is triggers edit when clicking anywhere on the card
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Stack
            alignItems="center"
            direction="row"
            color="grey_1200"
            gap="0"
            onClick={stopPropagation}
            className={classnames(styles.cardIconsContainer, { [styles.visible]: isLoadingData })}
        >
            <>
                <IconButton
                    intent="secondary"
                    shape="square"
                    variant="ghost"
                    size="sm"
                    loading={isLoadingData}
                    disabled={isLoadingData}
                    aria-label="edit"
                    onClick={setBriefInEdit}
                >
                    {!isLoadingData ? <PencilIcon /> : null}
                </IconButton>
                <DeleteBriefButton briefId={briefId} isDisabled={isLoadingData} />
            </>
        </Stack>
    );
};

export interface BriefCardProps {
    brief: BriefItem;
}
export const BriefCard = ({ brief }: BriefCardProps) => {
    const { setBriefInEdit, isLoadingBriefData } = useBriefEdit(brief.id);
    const { sendBigQueryEvent } = useBriefContext();
    const onBriefCardClick = () => {
        mixpanelEvents.clickOnExistingBrief();
        if (isLoadingBriefData) return;

        sendBigQueryEvent(bqEvents.professional_brief_clicked_on_brief_template({ templateId: brief.id }));
        setBriefInEdit();
    };

    return (
        <Card
            backgroundColor={{ hover: 'grey_100' }}
            cursor="pointer"
            marginBottom="4"
            onClick={onBriefCardClick}
            display="flex"
            borderRadius="lg"
            direction="row"
            padding="4"
            alignItems="center"
            gap="3"
            className={classnames(styles.cardContainer, styles.cardLeftBorder)}
        >
            <Container flex="0 0 auto">
                <BriefIcon size={24} color="black" />
            </Container>
            <Stack
                gap="3"
                direction="row"
                alignItems="center"
                justifyContent="spaceBetween"
                overflow="hidden"
                flex="1 1 auto"
            >
                <CardText title={brief.title} subtitle={formatBriefCardSubtitle(brief)} />
                <BriefActions briefId={brief.id} isLoading={isLoadingBriefData} />
            </Stack>
        </Card>
    );
};
