import React, { useRef, useState } from 'react';
import { Heading } from '@fiverr-private/typography';
import { Input } from '@fiverr-private/forms';
import { Stack } from '@fiverr-private/layout_components';
import { useBriefContext } from '../../../../common/BriefContext';
import { EditWrapper } from '../EditWrapper';
import { BriefSectionActions } from '../BriefSection/BriefSectionActions';
import * as styles from '../common/styles.ve.css';
import { SHORT_INPUT_LIMIT } from '../BriefSection/SectionTypes/ShortText';

interface TitleViewModeProps {
    onEditStart: () => void;
}

const TitleViewMode = ({ onEditStart }: TitleViewModeProps) => {
    const { editedBrief } = useBriefContext();

    return (
        <Stack gap="1" padding="3" justifyContent="spaceBetween" className={styles.sectionContainer}>
            <Heading size="h_md" color="heading" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {editedBrief?.title}
            </Heading>
            <BriefSectionActions onEditStart={onEditStart} />
        </Stack>
    );
};

interface TitleEditModeProps {
    value: string;
    onChange: (value: string) => void;
}

const TitleEditMode = ({ value, onChange }: TitleEditModeProps) => {
    const onContentChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value);
    const inputRef = useRef<HTMLInputElement>();
    return (
        <Input
            ref={inputRef as React.Ref<HTMLInputElement>}
            defaultValue={value}
            onChange={onContentChange}
            maxLength={SHORT_INPUT_LIMIT}
            autoFocus
        />
    );
};

export const BriefTitle = () => {
    const [isEditing, setIsEditing] = useState(false);
    const { editedBrief, updateEditedBrief } = useBriefContext();
    const [value, setValue] = useState(editedBrief?.title || '');

    const onEditStart = () => {
        setIsEditing(true);
    };
    const onSave = () => {
        setIsEditing(false);
        updateEditedBrief({ title: value });
    };

    const onCancel = () => {
        setIsEditing(false);
        setValue(editedBrief?.title || '');
    };

    return editedBrief?.title ? (
        <EditWrapper
            isEditing={isEditing}
            viewMode={<TitleViewMode onEditStart={onEditStart} />}
            editMode={<TitleEditMode value={value} onChange={setValue} />}
            onCancel={onCancel}
            onEditEnd={onSave}
        />
    ) : null;
};
