import React from 'react';
import { FilePdfIcon, FileUnknownIcon } from '@fiverr-private/icons';

const getFileExtentionIcon = (extension: string) => {
    // TODO: complete all available types with matching extensions
    switch (extension) {
        case 'pdf':
            return FilePdfIcon;
        default:
            return FileUnknownIcon;
    }
};

export const FileDisplayIcon = ({ extension }: { extension: string }) => {
    const Icon = getFileExtentionIcon(extension);
    return <Icon size={24} />;
};
