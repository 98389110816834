import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
    AtomsProvider,
    LocalizationContextProvider,
    PageName,
    localizationDataAtom,
    pageNameAtom,
    sellerAiModelsAtom,
} from '@fiverr-private/seller_pages_toolbox';
import {
    agencyAtom,
    gigsAtom,
    reviewsAtom,
    customOrderModalAtom,
    rolloutsAtom,
    agencySellerAtom,
    loyaltyDataAtom,
} from '../../atoms';
import { AgencyPage } from '../../components/AgencyPage';
import { AppProps } from './types';

const App = ({
    agency,
    currency,
    gigs,
    localization,
    reviews,
    seller,
    rollouts,
    loyaltyData,
    sellerAiModelsData,
}: AppProps) => {
    const queryClient = React.useMemo(() => new QueryClient(), []);

    return (
        <AtomsProvider
            atoms={[
                [agencyAtom, agency],
                [gigsAtom, gigs],
                [localizationDataAtom, localization],
                [reviewsAtom, reviews],
                [agencySellerAtom, seller],
                [pageNameAtom, 'agency_page' as PageName],
                [customOrderModalAtom, { isOpen: false, data: null }],
                [rolloutsAtom, rollouts],
                [loyaltyDataAtom, loyaltyData],
                [sellerAiModelsAtom, sellerAiModelsData],
            ]}
        >
            <QueryClientProvider client={queryClient}>
                <LocalizationContextProvider currency={currency}>
                    <AgencyPage />
                </LocalizationContextProvider>
            </QueryClientProvider>
        </AtomsProvider>
    );
};

export default App;
