import { useEffect, useState } from 'react';
import { theme } from '@fiverr-private/theme';
import { BriefSection } from '../types';
import { getWindow, isBrowser } from './getGlobals';

export const isAllowedCharactersValidation = (text) =>
    !text || !!text.match(/^[a-zA-Z0-9\s!@#$%^&*()\-_+=/[\]{};:'"”“‘’|\\~`,<>.? ]*$/m);
export type BreakpointsType = keyof typeof theme.numericBreakpoints;

export const getCurrentBreakpoint = (): BreakpointsType | undefined => {
    if (!isBrowser) {
        return;
    }
    const { numericBreakpoints } = theme;
    const { innerWidth } = getWindow() as Window;
    const breakpointsThatBiggestFromWindowWidth = Object.values(numericBreakpoints).filter(
        (breakpoint) => breakpoint >= innerWidth
    );
    const breakpoint = Math.min(...breakpointsThatBiggestFromWindowWidth);
    return Object.keys(numericBreakpoints).find((bp) => numericBreakpoints[bp] === breakpoint) as BreakpointsType;
};

export const useCurrentBreakpoint = (): BreakpointsType | undefined => {
    const [breakpoint, setBreakpoint] = useState<BreakpointsType | undefined>(getCurrentBreakpoint());

    useEffect(() => {
        const listener = () => setBreakpoint(getCurrentBreakpoint());
        (getWindow() as Window).addEventListener('resize', listener);
        return () => (getWindow() as Window).removeEventListener('resize', listener);
    }, []);

    return breakpoint;
};

export const autoResizeTextareaHeight = (textareaRefEl: HTMLInputElement | null, minHeight = 40) => {
    if (!textareaRefEl) {
        return;
    }
    textareaRefEl.style.height = '0px';
    textareaRefEl.style.height = `${textareaRefEl.scrollHeight || minHeight}px`;
};

export const hasSectionChanged = (originalSection: BriefSection, checkedSection: BriefSection) => {
    const checkedProperties: (keyof BriefSection)[] = ['content', 'included'];
    return checkedProperties.some((property) => originalSection[property] !== checkedSection[property]);
};
