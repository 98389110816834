import { mixpanel } from '@fiverr-private/obs';

const completeEventData = (options: { [key: string]: string | boolean | number } = {}) => ({
    ...options,
    source: options.source ? options.source : window.location.pathname,
});

export const clickOnExistingBrief = () => {
    mixpanel.track('clicked on existing brief', completeEventData());
};

export const clickOnCreateBrief = () => {
    mixpanel.track('click create a brief', completeEventData());
};

export const clickOnGenerateBrief = () => {
    mixpanel.track('clicked generate brief', completeEventData());
};

export const clickOnSendBrief = (options: {
    type: 'new brief' | 'existing brief';
    hasBriefTemplateChanged?: boolean;
}) => {
    mixpanel.track('clicked send brief', completeEventData(options));
};

export const clickOnSaveEditedBrief = () => {
    mixpanel.track('clicked edit and save brief', completeEventData());
};
