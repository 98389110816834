import React, { MouseEvent } from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';
import { Stack } from '@fiverr-private/layout_components';
import { IconButton } from '@fiverr-private/button';
import { BinIcon, PencilIcon } from '@fiverr-private/icons';
import * as styles from '../common/styles.ve.css';
import { BriefSection } from '../../../../types';
import { useBriefContext } from '../../../../common/BriefContext';
import { useExcludeSection } from '../useExcludeSection';

export interface BriefSectionWrapperProps {
    section?: BriefSection;
    onEditStart?: () => void;
    isEditing?: boolean;
    onEditEnd?: () => void;
}

export const BriefSectionActions = ({
    section,
    onEditStart,
    isEditing,
    onEditEnd = noop,
}: BriefSectionWrapperProps) => {
    const { isBriefInEditMode } = useBriefContext();
    const hideSection = useExcludeSection();

    const onHideHandler = (event: MouseEvent) => {
        event.stopPropagation();

        if (section && !section.mandatory) {
            hideSection(section);
        }

        if (isEditing) {
            // in mobile resolution, the remove button appears only when editing
            onEditEnd();
        }
    };

    if ((isBriefInEditMode && !isEditing) || section?.readOnly) {
        return null;
    }

    return (
        <Stack
            alignItems={section?.inline ? 'flexStart' : 'center'}
            direction="row"
            color="grey_1200"
            gap="4"
            marginLeft={section?.inline ? 'auto' : '0'}
        >
            {!!onEditStart && !isEditing && (
                <IconButton
                    className={styles.editIcon}
                    variant="ghost"
                    aria-label="edit"
                    onClick={onEditStart}
                    size="xs"
                >
                    <PencilIcon />
                </IconButton>
            )}
            {!!section && !section.mandatory && (
                <IconButton
                    className={classnames(isEditing ? styles.binIconEditMode : styles.binIconViewMode)}
                    variant="ghost"
                    aria-label="edit"
                    onClick={(e) => onHideHandler(e)}
                    size="xs"
                >
                    <BinIcon />
                </IconButton>
            )}
        </Stack>
    );
};
