import React from 'react';
import { BriefSection, DynamicSection, FileAttachmentData, SectionTypes } from '../../../../../types';
import { ShortTextEdit, ShortTextView } from './ShortText';
import { TextareaEdit, TextareaView } from './Textarea';
import { AttachmentsView, AttachmentsEdit } from './Attachments';

export interface BriefSectionContentProps {
    section: BriefSection;
    onChange: (content: string) => void;
    onAttachmentsChange: (attachments: FileAttachmentData[]) => void;
    onEditEnd: () => void;
    onCancel: () => void;
    shouldRemoveSection?: boolean;
}

export type BriefSectionViewContentProps = Pick<BriefSectionContentProps, 'section'>;
export const BriefSectionEditArea = (props: BriefSectionContentProps) => {
    switch (props.section.type) {
        case SectionTypes.shortText:
            return <ShortTextEdit {...props} />;
        case SectionTypes.attachments:
            return <AttachmentsEdit {...props} />;
        default: {
            if (props.section.type === SectionTypes.dynamic && (props.section as DynamicSection).EditComponent) {
                const SectionEditComponent = (props.section as DynamicSection).EditComponent;
                return <SectionEditComponent {...props} />;
            }

            return <TextareaEdit {...props} />;
        }
    }
};

export const BriefSectionViewArea = (props: BriefSectionViewContentProps) => {
    switch (props.section.type) {
        case SectionTypes.shortText:
            return <ShortTextView {...props} />;
        case SectionTypes.attachments:
            return <AttachmentsView {...props} />;
        default:
            if (props.section.type === SectionTypes.dynamic && (props.section as DynamicSection).ViewComponent) {
                const SectionViewComponent = (props.section as DynamicSection).ViewComponent;
                return <SectionViewComponent {...props} />;
            }

            return <TextareaView {...props} />;
    }
};
