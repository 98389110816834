export enum STEPS_IDS {
    generate = 'generate',
    loading = 'loading',
    editBrief = 'edit_brief',
    done = 'done',
    generationError = 'generation_error',
    home = 'home',
}

export const statsPrefix = 'packages.ai_brief';
