import React, { useMemo } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { Container } from '@fiverr-private/layout_components';
import { theme } from '@fiverr-private/theme';
import { useBriefData } from '../../../hooks';
import StarsIcon from '../../../assets/stars_icon.gif';
import StarsIconAnimated from '../../../assets/stars_icon_animated.gif';
import { bqEvents } from '../../../monitoring/bqEvents';
import * as mixpanelEvents from '../../../monitoring/mixpanel';
import { useBriefContext } from '../../../common/BriefContext';
import { isUserInputTooShort } from '../Validation';

const buttonBackground = 'linear-gradient(266.18deg, #1e1e1e 53.81%, #332d5b 77.9%, #7c4fa5 121.78%)';

export interface GenerateButtonProps {
    fullWidth?: boolean;
}
export const GenerateButton = ({ fullWidth }: GenerateButtonProps) => {
    const [isButtonHover, setIsButtonHover] = React.useState(false);
    const { handleGenerate } = useBriefData();
    const { sendBigQueryEvent, userInput } = useBriefContext();

    const isDisabled = useMemo(() => isUserInputTooShort(userInput), [userInput]);

    const onButtonClick = () => {
        sendBigQueryEvent(bqEvents.professional_brief_clicked_on_generate_your_brief());
        mixpanelEvents.clickOnGenerateBrief();
        handleGenerate();
    };

    return (
        <Button
            style={{
                background: isDisabled ? theme.backgroundColors.grey_300 : buttonBackground,
                width: fullWidth ? '100%' : 'fit-content',
            }}
            onMouseEnter={() => setIsButtonHover(true)}
            onMouseLeave={() => setIsButtonHover(false)}
            onClick={onButtonClick}
            disabled={isDisabled}
        >
            <Container
                style={{
                    backgroundImage: `url(${isButtonHover ? StarsIconAnimated : StarsIcon})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
                width="13px"
                height="12px"
                marginRight="1"
            />
            <I18n k="ai_brief.prompt_step.cta" />
        </Button>
    );
};
