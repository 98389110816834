import React, { useEffect, useRef } from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { useBriefContext } from '../../../../common/BriefContext';

export interface EditWrapperProps {
    isEditing: boolean;
    viewMode: React.ReactNode;
    editMode: React.ReactNode;
    onCancel: () => void;
    onEditEnd: () => void;
    hideActions?: boolean;
    isDisabled?: boolean;
    shouldRemoveSection?: boolean;
}

export const EditWrapper = ({
    isEditing,
    viewMode,
    editMode,
    onEditEnd,
    onCancel,
    hideActions,
    isDisabled,
    shouldRemoveSection,
}: EditWrapperProps) => {
    const editModeRef = useRef<HTMLElement>(null);
    const { setIsBriefInEditMode } = useBriefContext();

    useEffect(() => {
        if (isEditing) {
            editModeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
        setIsBriefInEditMode(isEditing);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);
    if (!isEditing) {
        return <Container>{viewMode}</Container>;
    }

    return (
        <Container ref={editModeRef}>
            {editMode}
            {!hideActions && (
                <Stack alignItems="center" justifyContent="flexEnd" paddingTop="1.5">
                    <Button intent="secondary" size="sm" variant="ghost" onClick={onCancel}>
                        <I18n k={`ai_brief.edit_brief.${shouldRemoveSection ? 'remove' : 'cancel'}`} />
                    </Button>
                    <Button disabled={isDisabled} intent="primary" size="sm" variant="outline" onClick={onEditEnd}>
                        <I18n k="ai_brief.edit_brief.save" />
                    </Button>
                </Stack>
            )}
        </Container>
    );
};
