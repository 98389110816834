import { useMemo } from 'react';
import { GigData } from '@fiverr-private/seller_pages_toolbox';
import { useRollouts } from '../../../hooks/useRollouts';
import { AGENCY_ROLLOUTS } from '../../../../server/middleware/addRollouts/constants';

export const useGigsWithoutRating = (gigs: GigData[]) => {
    const rollouts = useRollouts() ?? {};
    const inGigBoostingRollout = rollouts[AGENCY_ROLLOUTS.STARLINGS_BOOSTING_NEW_GIGS];
    const cutRating = inGigBoostingRollout;
    return useMemo(() => {
        if (!cutRating) return gigs;
        return gigs.map((gig) => ({
            ...gig,
            buying_review_rating_count: undefined,
            buying_review_rating: undefined,
        }));
    }, [gigs, cutRating]);
};
