import React from 'react';
import { useQuery } from 'react-query';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { Container, Stack } from '@fiverr-private/layout_components';
import { BriefItem } from '../../types';
import { containerPaddingXBySmBreakpoint } from '../common/containerStylePaddingX.ve.css';
import { briefTemplatesQueryKey } from '../../common/queryClient';
import { Layout } from '../common/Layout';
import { useBriefContext } from '../../common/BriefContext';
import { ContactSellerButton } from '../common/ContactSellerButton';
import { AddBriefButton } from './AddBriefButton';
import { BriefCard } from './BriefCard';

export const HomeScreen = () => {
    const { data: briefTemplates } = useQuery<BriefItem[]>(briefTemplatesQueryKey, { enabled: false });
    const { shouldShowContactSellerButton } = useBriefContext();

    const containerProps: React.ComponentProps<typeof Container> = shouldShowContactSellerButton
        ? { maxHeight: '260px', overflow: 'auto' }
        : {};

    return (
        <Layout
            withDividerUnderSubTitle
            withTitle
            containerPaddingXByBreakpoint={containerPaddingXBySmBreakpoint}
            content={
                <Stack gap="0" direction="column" paddingY="6">
                    <AddBriefButton />

                    <Text marginY="6" fontWeight="semibold" size="b_md">
                        <I18n k="ai_brief.reuse_brief.old_brief_title" />
                    </Text>

                    <Container {...containerProps}>
                        {briefTemplates?.map((brief) => (
                            <BriefCard brief={brief} key={brief.id} />
                        ))}
                    </Container>

                    {shouldShowContactSellerButton && (
                        <Container marginTop="5">
                            <ContactSellerButton withSeparator />
                        </Container>
                    )}
                </Stack>
            }
        />
    );
};
