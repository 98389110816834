import React from 'react';
import { noop } from 'lodash';
import { Card, Stack } from '@fiverr-private/layout_components';
import { FileAttachmentData } from '../../../../../../../types';
import { DeleteButton } from './DeleteButton';
import { AttachmentPreview } from './AttachmentPreview';
import { isFileLoaded } from './utils';

interface AttachmentProps {
    attachment: FileAttachmentData;
    onDelete?: () => void;
    isEditing?: boolean;
}

export const Attachment = ({ attachment, onDelete = noop, isEditing = false }: AttachmentProps) => {
    const isLoaded = isFileLoaded(attachment);
    return (
        <Card
            direction="column"
            width="128px"
            height="92px"
            flexShrink="0"
            borderColor="grey_300"
            borderRadius="sm"
            position="relative"
            overflow="hidden"
            backgroundColor="grey_100"
        >
            <AttachmentPreview attachment={attachment} />
            {isLoaded && onDelete && isEditing && <DeleteButton onDelete={onDelete} />}
        </Card>
    );
};

export interface AttachmentsListProps {
    attachmentsList?: FileAttachmentData[];
    isEditing?: boolean;
    onDelete?: (id: string) => void;
}
export const AttachmentsList = ({ attachmentsList = [], onDelete = noop, isEditing }: AttachmentsListProps) => {
    if (!attachmentsList.length) return null;

    return (
        <Stack
            gap="3"
            backgroundColor="white"
            width="100%"
            padding="3"
            borderRadius="sm"
            borderColor="grey_400"
            overflowX="auto"
        >
            {attachmentsList.map((attachment) => (
                <Attachment
                    attachment={attachment}
                    key={attachment.id}
                    onDelete={() => onDelete(attachment.id)}
                    isEditing={isEditing}
                />
            ))}
        </Stack>
    );
};
