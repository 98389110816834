import React from 'react';
import { Card, Container, Stack } from '@fiverr-private/layout_components';
import { PlusOutlineIcon } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { Stepper } from '@fiverr-private/orca';
import { STEPS_IDS } from '../../../common/constants';
import * as styles from '../styles.ve.css';
import { CardText } from '../CardText';
import { useBriefContext } from '../../../common/BriefContext';
import { bqEvents } from '../../../monitoring/bqEvents';
import * as mixpanelEvents from '../../../monitoring/mixpanel';

export const AddBriefButton = () => {
    const { sendBigQueryEvent } = useBriefContext();
    const { navigateById } = Stepper.useContext();
    const navigateToCreateStep = () => navigateById(STEPS_IDS.generate);

    const onAddBriefButtonClick = () => {
        sendBigQueryEvent(bqEvents.professional_brief_clicked_on_new_brief());
        mixpanelEvents.clickOnCreateBrief();
        navigateToCreateStep();
    };

    return (
        <Card
            backgroundColor={{ hover: 'grey_100' }}
            cursor="pointer"
            display="flex"
            borderRadius="lg"
            direction="row"
            padding="4"
            alignItems="center"
            gap="3"
            className={styles.cardContainer}
            onClick={onAddBriefButtonClick}
        >
            <Container flex="0 0 auto">{<PlusOutlineIcon size={20} color="black" />}</Container>
            <Stack
                gap="3"
                direction="row"
                alignItems="center"
                justifyContent="spaceBetween"
                overflow="hidden"
                flex="1 1 auto"
            >
                <CardText
                    title={<I18n k="ai_brief.reuse_brief.new_card.title" />}
                    subtitle={<I18n k="ai_brief.reuse_brief.new_card.content" />}
                />
            </Stack>
        </Card>
    );
};
