import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash';
import { FileAttachmentData } from '../../../../../../../types';
import { useBriefContext } from '../../../../../../../common/BriefContext';

export const MAX_FILES = 20;
export const UPLOAD_CONFIG = {
    objectName: 'brief',
    attachmentName: 'attachment',
    getPolicyUrl: '/briefs/api/attachments/policy',
    createPendingAttachmentIdUrl: '/briefs/api/attachments/create_pending_attachment',
    shouldBeRenamed: false,
};

export interface useAttachmentsProps {
    initialAttachments?: FileAttachmentData[];
    onChange: (attachments: FileAttachmentData[]) => void;
}

const defaultAttachments = [];
export const useAttachments = ({ initialAttachments = defaultAttachments, onChange }: useAttachmentsProps) => {
    const [attachments, setAttachments] = useState<{ [key: string]: FileAttachmentData }>(
        keyBy(initialAttachments, 'id')
    );
    const sortedAttachments = useMemo(
        () => Object.values(attachments).sort(({ sortId: a }, { sortId: b }) => Number(b) - Number(a)),
        [attachments]
    );

    const { updateEditedBrief, editedBrief } = useBriefContext();

    useEffect(() => {
        onChange(sortedAttachments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedAttachments]);

    const upsertFile = useCallback(
        (attachment: FileAttachmentData) =>
            setAttachments((files) => {
                if (attachment.error) {
                    setTimeout(() => {
                        throw attachment.error;
                    });

                    const updatedFiles = { ...files };
                    delete updatedFiles[attachment.id];
                    return updatedFiles;
                }

                return { ...files, [attachment.id]: attachment };
            }),
        [setAttachments]
    );

    const removeFile = (id: string) => {
        setAttachments((files) => {
            const updatedFiles = { ...files };
            delete updatedFiles[id];
            return updatedFiles;
        });

        const deletedAttachmentIds = editedBrief?.deletedAttachmentIds || [];
        deletedAttachmentIds.push(id);
        updateEditedBrief({ deletedAttachmentIds });
    };

    const clearAttachments = () => {
        Object.keys(attachments).forEach(removeFile);
    };

    return {
        attachments: sortedAttachments,
        upsertFile,
        removeFile,
        clearAttachments,
        isDisabled: sortedAttachments.length >= MAX_FILES,
    };
};
