import React from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { I18n } from '@fiverr-private/i18n-react';
import { Title } from '../Title';
import { SubTitleWithIcon } from '../SubTitleWithIcon';

interface LayoutProps {
    applyContainerPaddingOnContent?: boolean;
    containerPaddingXByBreakpoint: string;
    withTitle: boolean;
    withDividerUnderSubTitle: boolean;
    subTitleKey?: string;
    content: React.ReactNode;
    footer?: React.ReactNode;
    buttons?: React.ReactNode;
    goBack?: () => void;
    onClose?: () => void;
    goBackClassName?: string;
    withDivider?: boolean;
    title?: React.ReactNode;
}
export const Layout = ({
    applyContainerPaddingOnContent = true,
    containerPaddingXByBreakpoint,
    withTitle,
    content,
    footer,
    buttons,
    subTitleKey,
    withDividerUnderSubTitle,
    goBack,
    goBackClassName,
    title,
    onClose,
    withDivider = true,
}: LayoutProps) => (
    <Stack gap="0" direction="column" height="100%" overflowY="auto" withDivider={withDivider}>
        {withTitle ? (
            <Container>
                <Title
                    title={title}
                    goBack={goBack}
                    onClose={onClose}
                    goBackClassName={goBackClassName}
                    classNameContainer={containerPaddingXByBreakpoint}
                />
            </Container>
        ) : null}
        <Stack flex="1" overflow="hidden" direction="column" gap="0">
            <Stack overflowY="auto" direction="column" gap="0" withDivider={withDividerUnderSubTitle} flexGrow="1">
                {subTitleKey && (
                    <SubTitleWithIcon
                        title={<I18n k={subTitleKey} />}
                        classNameContainer={containerPaddingXByBreakpoint}
                    />
                )}
                <Container
                    height="100%"
                    width="100%"
                    className={applyContainerPaddingOnContent ? containerPaddingXByBreakpoint : undefined}
                >
                    {content}
                </Container>
            </Stack>
        </Stack>
        {footer}
        {buttons && (
            <Container>
                <Stack
                    flex="0 0 auto"
                    paddingY="6"
                    direction="row"
                    gap="0"
                    className={containerPaddingXByBreakpoint}
                    alignItems="center"
                    justifyContent="spaceBetween"
                >
                    {buttons}
                </Stack>
            </Container>
        )}
    </Stack>
);
