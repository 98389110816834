import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { Container, Stack } from '@fiverr-private/layout_components';
import { responsiveDisplay } from '../../util';
import { Chip } from '../../../common/Chip';
import { useIncludeSection } from '../useIncludeSection';
import { useBriefContext } from '../../../../common/BriefContext';
import { showBoxShadowTopOnly } from './styles.ve.css';

export const ExcludedSectionsNavBar = () => {
    const { editedBrief } = useBriefContext();
    const includeSection = useIncludeSection();

    if (!editedBrief) {
        return null;
    }

    const excludedSections = editedBrief.sections.filter((section) => !section.included);

    return (
        <Container
            className={showBoxShadowTopOnly}
            boxShadow="z2"
            display={responsiveDisplay('flex', false)}
            direction="column"
        >
            <Stack direction="column" padding="3" gap="3" flexShrink={0}>
                <Text color="bodySecondary" size="b_xs">
                    <I18n k="ai_brief.edit_brief.add_sections" />
                </Text>
                <Stack direction="row" overflowX="auto" gap="2">
                    {excludedSections.map((section) => (
                        <Chip key={section.title} title={section.title} onClick={() => includeSection(section)} />
                    ))}
                </Stack>
            </Stack>
        </Container>
    );
};
