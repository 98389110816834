import React, { useEffect, useRef, useState } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import pathfinder from '@fiverr-private/pathfinder';
import { FreelancerReviews, FILTER_TYPES } from '@fiverr-private/reviews';
import { useCurrentPageName, useLocalizationContext } from '@fiverr-private/seller_pages_toolbox';
import { logger } from '@fiverr-private/obs';
import { Box } from '@fiverr-private/theme';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { mixpanel } from '../../monitoring/mixpanel';
import { useAgencyGigs } from '../../hooks/useAgencyGigs';
import { useAgencyReviews } from '../../hooks/useAgencyReviews';
import { StickyHeader } from '../StickyHeader';
import { AgencySeller } from '../../types';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';

const onRenderError = (error: any) => {
    logger.error(new Error('Failed while rendering the AgencyReviews Component'), { cause: error });
};

export const AgencyReviews = () => {
    const [rendered, setRendered] = useState<boolean>(false);
    const { pageCtxId } = getContext();
    const currentPageName = useCurrentPageName();
    const { currency } = useLocalizationContext();
    const gigs = useAgencyGigs();
    const reviews = useAgencyReviews();
    const { approvedGigsCount } = useAgencySellerData(selectSellerSlice);

    const showBuyingReviews = Number(reviews?.buying_reviews?.reviews?.length) > 0 && approvedGigsCount > 0;
    const observableRef = useRef(null);

    useEffect(() => {
        if (showBuyingReviews && !rendered) {
            mixpanel.event('Reviews viewed');
            setRendered(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBuyingReviews, rendered]);

    if (!showBuyingReviews) {
        return null;
    }

    const enrichedReviewsConfig = {
        pageCtxId,
        displayRelevant: true,
        displayBreakdown: true,
        pageName: 'agency page',
        biEventGroup: currentPageName,
        type: FILTER_TYPES.relevant,
        gigsData: gigs,
        pathFinderConfig: {
            path: 'agency_page_api_reviews',
        },
    };

    const reviewsTranslationUrl = pathfinder('agency_page_api_ugc_translate');

    return (
        <ErrorBoundary onError={onRenderError}>
            <Box id={StickyHeader.TabName.Reviews}>
                <div ref={observableRef} data-testid="agencyReviews">
                    {/* @ts-expect-error | "@fiverr-private/reviews" package was migrated to Package Core V4, but it's still JS-based, so we're temporary suppressing its wrong-compiled types in here */}
                    <FreelancerReviews
                        enableSearchFilter
                        initialState={{ ...reviews?.buying_reviews, ...enrichedReviewsConfig }}
                        currencyConfig={currency}
                        translationUrl={reviewsTranslationUrl}
                    />
                </div>
            </Box>
        </ErrorBoundary>
    );
};

const selectSellerSlice = (seller: AgencySeller) => ({
    approvedGigsCount: seller.approvedGigsCount,
});
