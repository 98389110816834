import React, { MutableRefObject, useRef, useState } from 'react';
import { groupBy } from 'lodash';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '@fiverr-private/disclosure';
import { Container } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Collapse, NodeGroup, EASING } from '@fiverr-private/transition';
import { useBriefContext } from '../../../../common/BriefContext';
import { Chip } from '../../../common/Chip';
import { BriefSection } from '../../../../types';
import { getNearestOpacity } from '../../util';
import { useIncludeSection } from '../useIncludeSection';

const rootGroup = 'Root';

const STARTING_DELAY = 300;

const AnimatedChipGroup = ({
    sections,
    includeSection,
    delayIndexRef,
}: {
    sections: BriefSection[];
    includeSection: (section: BriefSection) => void;
    delayIndexRef: MutableRefObject<number>;
}) => {
    const [isFirstRender, setIsFirstRender] = useState(true);

    return (
        <NodeGroup
            data={sections}
            keyAccessor={(d) => d.title}
            start={() => ({ opacity: [isFirstRender ? 0 : 1], x: [isFirstRender ? -24 : 0] })}
            enter={(_, index) => {
                const currentIndex = delayIndexRef.current;
                if (!sections[index]?.included) {
                    delayIndexRef.current = delayIndexRef.current + 1;
                }

                return {
                    opacity: [1],
                    x: [0],
                    timing: {
                        duration: 200,
                        delay: currentIndex * 50 + STARTING_DELAY,
                        ease: EASING.easeIn,
                    },
                };
            }}
        >
            {(nodes) => (
                <Container display="flex" direction="column">
                    {nodes.map(({ key, state: { opacity, x } }, index) => {
                        const shouldShow = !sections[index]?.included;
                        return (
                            <Container
                                transform={`translate(${x}px, 0)`}
                                opacity={getNearestOpacity(opacity)}
                                marginBottom={shouldShow ? '3' : '0'}
                                key={key}
                            >
                                <Collapse
                                    in={shouldShow}
                                    duration={isFirstRender ? 'fast1' : 'moderate1'}
                                    onAnimationEnd={() => setIsFirstRender(false)}
                                >
                                    <Container>
                                        <Chip
                                            key={key}
                                            title={key}
                                            onClick={() => {
                                                includeSection(sections[index]);
                                                delayIndexRef.current = 0;
                                            }}
                                        />
                                    </Container>
                                </Collapse>
                            </Container>
                        );
                    })}
                </Container>
            )}
        </NodeGroup>
    );
};
export const ExcludedSectionsList = () => {
    const delayIndexRef = useRef<number>(-1);
    const { editedBrief } = useBriefContext();
    const includeSection = useIncludeSection();
    if (!editedBrief) {
        return null;
    }

    const sectionGroups: Record<string, BriefSection[]> = groupBy(
        editedBrief.sections,
        (section) => section.group ?? rootGroup
    );

    const { [rootGroup]: rootGroupSections = [], ...otherGroups } = sectionGroups;

    return (
        <>
            <AnimatedChipGroup
                sections={rootGroupSections}
                includeSection={includeSection}
                delayIndexRef={delayIndexRef}
            />
            {Object.keys(otherGroups).map((group) => (
                <Container overflow="visible" key={group}>
                    <Collapsible key={group} defaultExpanded>
                        <CollapsibleHeader chevronSize="sm">
                            <Text size="b_sm" color="bodySecondary">
                                {group}
                            </Text>
                        </CollapsibleHeader>
                        <CollapsibleContent>
                            <AnimatedChipGroup
                                sections={otherGroups[group]}
                                includeSection={includeSection}
                                delayIndexRef={delayIndexRef}
                            />
                        </CollapsibleContent>
                    </Collapsible>
                </Container>
            ))}
        </>
    );
};
