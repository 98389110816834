import { merge } from 'lodash';
import { bigQuery, logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { bqEvents } from './bqEvents';

const ENTITY = 'client';
const FEATURE_NAME = 'fiverr_pro';
const ACTION_TYPE = 'imp';
const GROUP = 'user_impression';
const SUB_GROUP = 'fiverr_pro';
declare type ValuesOf<T> = T[keyof T];

const getBaseEvent = (
    source: string,
    componentName: string,
    categoryId: number | null,
    subCategoryId: number | null,
    isBriefInEditMode: boolean,
    sellerId?: number
) => {
    const { organizationId, userId, pageCtxId } = getContext();

    return {
        entity: ENTITY,
        group: GROUP,
        sub_group: SUB_GROUP,
        user: {
            id: userId,
        },
        organization: {
            id: organizationId,
        },
        action: {
            type: ACTION_TYPE,
        },
        seller: {
            id: sellerId,
        },
        page: {
            ctx_id: pageCtxId,
            name: source,
            component: {
                name: componentName,
            },
            element: {
                state: isBriefInEditMode ? 'edit' : 'create',
            },
        },
        buyer: {
            request: {
                category_id: categoryId,
                subcategory_id: subCategoryId,
            },
        },
        feature: {
            name: FEATURE_NAME,
        },
    };
};

interface SendBigQueryParams {
    componentName: string;
    source: string;
    categoryId: number | null;
    subCategoryId: number | null;
    isBriefInEditMode: boolean;
    sellerId?: number;
}

export type BigQueryEvent = ReturnType<ValuesOf<typeof bqEvents>>;
export const bigQueryEvent =
    ({ source, componentName, categoryId, subCategoryId, isBriefInEditMode, sellerId }: SendBigQueryParams) =>
    (event: BigQueryEvent) => {
        const baseEvent = getBaseEvent(source, componentName, categoryId, subCategoryId, isBriefInEditMode, sellerId);
        if (typeof window === 'undefined') {
            // This is because it's using module variables for biContext
            // If we were to use it on the server, users would override each other
            logger.error('sendBqEvent is not supported on server side');
            return;
        }

        const enrichedEvent = merge({}, baseEvent, event);
        bigQuery.send(enrichedEvent);
    };
