import { keyBy } from 'lodash';
import { BriefSection, ConnectedAttachmentData, FileAttachmentData, SectionTypes } from '../types';
import { ATTACHMENTS_SECTION_TITLE } from '../hooks/useBriefData';
import { BriefSectionsDTO } from './types';

export const convertBriefSectionsDtoToBriefSections = (content: BriefSectionsDTO): BriefSection[] =>
    // Saved data to displayed data
    Object.keys(content).map((key) => ({ title: key, ...content[key] }));

export const convertBriefSectionToBriefSectionsDTO = (sections: BriefSection[]): BriefSectionsDTO =>
    // Displayed data to saved data
    keyBy(sections, 'title');

export const extractAttachmentsFromBrief = (
    sections: BriefSection[]
): [sections: BriefSection[], attachments: FileAttachmentData[]] => {
    const attachments: FileAttachmentData[] = [];
    const dataSections = sections.filter((section) => {
        if (section.type === SectionTypes.attachments) {
            attachments.push(...(section.attachments || []));
            return false;
        }

        return true;
    });

    return [dataSections, attachments];
};

export const convertAttachmentsToSection = (
    sections: BriefSectionsDTO,
    attachments: ConnectedAttachmentData[] = []
): BriefSectionsDTO => {
    const updatedBriefSections = { ...sections };
    const isIncluded = !!attachments.length;
    updatedBriefSections[ATTACHMENTS_SECTION_TITLE] = {
        content: '',
        included: isIncluded,
        type: SectionTypes.attachments,
        attachments,
    };

    return updatedBriefSections;
};
