import { useBriefContext } from '../../../common/BriefContext';
import { BriefSection } from '../../../types';

export const useUpdateSection = () => {
    const { updateEditedBrief } = useBriefContext();

    return (section: BriefSection, updateSectionValues: Partial<BriefSection>) => {
        updateEditedBrief(null, section.title, { ...updateSectionValues, isMovedFromExcluded: false });
    };
};
