import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useConst } from '@fiverr-private/hooks';

export const QueryProvider = ({ children }: { children: ReactNode }) => {
    const queryClient = useConst(() => new QueryClient({ defaultOptions: { queries: { cacheTime: Infinity } } }));
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export const briefTemplatesQueryKey = 'briefTemplatesQueryKey';
export const briefResponseQueryKey = 'briefResponseQueryKey';
export const deleteBriefResponseQueryKey = 'deleteBriefResponseQueryKey';
export const sendBriefQueryKey = 'sendBriefQueryKey';
