import React from 'react';
import { Text } from '@fiverr-private/typography';
import { Container, Stack } from '@fiverr-private/layout_components';
import { useBriefContext } from '../../../common/BriefContext';
import * as styles from './styles.ve.css';

interface ChipProps {
    title: string;
    onClick: () => void;
}
// TODO: Add to Penta
export const Chip = ({ title, onClick }: ChipProps) => {
    const { isBriefInEditMode } = useBriefContext();

    return (
        <Stack
            width="fit-content"
            cursor={isBriefInEditMode ? undefined : 'pointer'}
            onClick={onClick}
            direction="row"
            gap="2"
            borderColor="grey_500"
            borderStyle="solid"
            borderWidth="sm"
            paddingX="4"
            paddingY="2"
            className={styles.chip}
        >
            <Text overflow="hidden" size="b_sm">
                <Stack gap="2" direction="row" alignItems="center" justifyContent="flexStart">
                    <Container as="span" color="black">
                        ↳
                    </Container>
                    <Text size="b_sm" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="bodyPrimary">
                        {title}
                    </Text>
                </Stack>
            </Text>
        </Stack>
    );
};
