import React from 'react';

const StaticStarsIcon = ({ fill = 'white' }: { fill: string }) => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.2697 0L13.2406 1.83314L15.061 2.81082L13.2406 3.78849L12.2697 5.62163L11.2987 3.78849L9.47831 2.81082L11.2987 1.83314L12.2697 0ZM5.64404 2.31661L7.60717 6.02318L11.2881 8.00003L7.60717 9.97682L5.64404 13.6834L3.68089 9.97682L0 8.00003L3.68089 6.02318L5.64404 2.31661ZM14.2328 11.2124L13.0059 8.89576L11.7789 11.2124L9.47831 12.4479L11.7789 13.6834L13.0059 16L14.2328 13.6834L16.5333 12.4479L14.2328 11.2124Z"
            fill={fill}
        />
    </svg>
);

export default StaticStarsIcon;
