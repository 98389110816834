import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import * as styles from '../styles.ve.css';

export interface CardTextProps {
    title: string | React.ReactNode;
    subtitle: string | React.ReactNode;
}
export const CardText = ({ title, subtitle }: CardTextProps) => (
    <Stack direction="column" gap="0.5" overflow="hidden">
        <Text fontWeight="semibold" size="b_sm" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {title}
        </Text>
        <Text className={styles.subtitle} color="bodySecondary">
            {subtitle}
        </Text>
    </Stack>
);
