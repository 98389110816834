import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Link, Text } from '@fiverr-private/typography';
import { Container, Stack } from '@fiverr-private/layout_components';
import { useBriefContext } from '../../../common/BriefContext';
import { Separator } from './Seperator';

const TRANSLATION_PREFIX = 'ai_brief.contact_seller';

export interface ContactSellerButtonProps {
    withSeparator?: boolean;
}
export const ContactSellerButton = ({ withSeparator }: ContactSellerButtonProps) => {
    const { seller, onContactSellerClick } = useBriefContext();

    return (
        <Container display="flex" direction="column" gap="3" width="100%">
            {withSeparator && <Separator />}

            <Stack gap="1.5" justifyContent="center" alignItems="center" height="36px">
                <Text size="b_sm">
                    <I18n k={`${TRANSLATION_PREFIX}.description`} params={{ userName: seller?.displayName || '' }} />
                </Text>

                <Link onClick={onContactSellerClick} cursor="pointer" fontWeight="semibold" fontSize="b_sm">
                    <I18n k={`${TRANSLATION_PREFIX}.cta`} />
                </Link>
            </Stack>
        </Container>
    );
};
