import React, { useEffect, useRef, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import classnames from 'classnames';
import { Stack } from '@fiverr-private/layout_components';
import { Types } from '@fiverr-private/theme';
import * as styles from '../common/styles.ve.css';
import { BriefSection, FileAttachmentData, SectionTypes } from '../../../../types';
import { useBriefContext } from '../../../../common/BriefContext';
import { SectionTitle } from '../../../common/SectionTitle';
import { EditWrapper } from '../EditWrapper';
import { useExcludeSection } from '../useExcludeSection';
import { useUpdateSection } from '../useUpdateSection';
import { BriefSectionActions } from './BriefSectionActions';
import { BriefSectionEditArea, BriefSectionViewArea } from './SectionTypes';

export interface BriefSectionProps {
    isRequired?: boolean;
    section: BriefSection;
    onChange?: (content: object | string) => void;
    opacity?: Types.SelectorOpacityType;
}

export const Section = ({ section, onChange, isRequired = false, opacity = '1' }: BriefSectionProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const contentRef = useRef(null);
    const [content, setContent] = useState(section.content);
    const [attachments, setAttachments] = useState<FileAttachmentData[] | undefined>(section.attachments);
    const { editedBrief, setIsBriefInEditMode, isBriefInEditMode, allowSaveAfterEditOnly } = useBriefContext();

    const hideSection = useExcludeSection();
    const updateSection = useUpdateSection();

    const isAttachmentsChanged = (): boolean =>
        section?.attachments?.map((s) => s.attachment_id).join('') !==
        attachments?.map((a) => a.attachment_id).join('');
    const isContentChanged = (): boolean => section?.content !== content;
    const shouldRemoveSection = (): boolean => !section.mandatory && !!section.isMovedFromExcluded;

    useEffect(() => {
        // set in edit mode on add to included
        if (isRequired) {
            return;
        }

        const savedSection = editedBrief?.sections?.find((_section) => _section.title === section.title);
        if (!savedSection?.included || savedSection.focused) {
            setIsEditing(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedBrief, section?.focused]);

    const onEditStartHandler = () => {
        setIsEditing(true);
        setIsBriefInEditMode(true);
        section.onEditStart?.(section);
    };

    const onEditEndHandler = () => {
        setIsEditing(false);
        setIsBriefInEditMode(false);
    };

    const onCancelHandler = () => {
        const savedSection = editedBrief?.sections?.find((_section) => _section.title === section.title);

        // cancelling just added section should return it to the excluded sections list
        if (shouldRemoveSection()) {
            hideSection(section);
        }

        if (savedSection) {
            setContent(savedSection.content);
            setAttachments(savedSection.attachments);
        }

        onEditEndHandler();
    };

    useEffect(() => {
        if (!isEditing) {
            const isChanged = section.type === SectionTypes.attachments ? isAttachmentsChanged() : isContentChanged();
            let updatedContent = {
                focused: false,
            } as Partial<BriefSection>;

            if (isChanged || (shouldRemoveSection() && !isChanged)) {
                updatedContent = {
                    ...updatedContent,
                    content: cloneDeep(content),
                    attachments: attachments?.length ? cloneDeep(attachments) : attachments,
                } as Partial<BriefSection>;
            }

            if (onChange) {
                onChange(content);
            }

            updateSection(section, { ...updatedContent });
            section?.onEditEnd?.({ ...section, ...updatedContent });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    if (!section.included && !isRequired) {
        return null;
    }

    const TitleComponent = section.TitleComponent ? section.TitleComponent : SectionTitle;

    const isMandatoryAndEmpty = section.mandatory && isEmpty(section.content);

    const onSectionClick = () => {
        if (isBriefInEditMode || isEditing || section.readOnly) {
            return;
        }

        onEditStartHandler();
    };

    const getCursor = () => {
        if (isEditing || isBriefInEditMode || section.readOnly) {
            return 'default';
        }

        return 'pointer';
    };

    const isDisabledSaveAction = () => {
        if (!isEditing) return true;

        const isContentExists = section.type === SectionTypes.attachments ? !!attachments?.length : !!content;
        const isChanged = section.type === SectionTypes.attachments ? isAttachmentsChanged() : isContentChanged();

        // allow saving section without changes to persist in the included list
        if (shouldRemoveSection() && isContentExists) return false;

        if (!isContentExists) return true;

        return allowSaveAfterEditOnly && !isChanged;
    };

    return (
        <Stack
            opacity={opacity}
            cursor={getCursor()}
            onClick={onSectionClick}
            direction={!isEditing && section.inline ? 'row' : 'column'}
            gap="1"
            padding="3"
            justifyContent={section.inline ? 'flexStart' : 'spaceBetween'}
            className={classnames({
                [styles.mandatoryAndEmptySection]: isMandatoryAndEmpty,
                [styles.sectionContainerEditMode]: isEditing,
                [styles.sectionContainer]: !isEditing,
            })}
            ref={contentRef}
            {...(isEditing
                ? {
                      borderRadius: 'xl',
                      borderWidth: 'sm',
                      borderStyle: 'solid',
                  }
                : {})}
        >
            <Stack direction="row" justifyContent="spaceBetween">
                <TitleComponent title={section.title} isEditing={isEditing} section={section} />
                {!section.inline && (
                    <BriefSectionActions
                        section={section}
                        onEditStart={onEditStartHandler}
                        isEditing={isEditing}
                        onEditEnd={onEditEndHandler}
                    />
                )}
            </Stack>
            <EditWrapper
                isEditing={isEditing}
                viewMode={<BriefSectionViewArea section={section} />}
                editMode={
                    <BriefSectionEditArea
                        section={section}
                        onAttachmentsChange={setAttachments}
                        onChange={setContent}
                        onEditEnd={onEditEndHandler}
                        onCancel={onCancelHandler}
                        shouldRemoveSection={shouldRemoveSection()}
                    />
                }
                isDisabled={isDisabledSaveAction()}
                onCancel={onCancelHandler}
                onEditEnd={onEditEndHandler}
                hideActions={section.hideActions}
                shouldRemoveSection={shouldRemoveSection()}
            />
            {section.inline && (
                <BriefSectionActions
                    section={section}
                    onEditStart={onEditStartHandler}
                    isEditing={isEditing}
                    onEditEnd={onEditEndHandler}
                />
            )}
        </Stack>
    );
};
