import React, { FunctionComponent } from 'react';
import { Text } from '@fiverr-private/typography';
import type { Validation } from '../Validation';

interface ErrorMessageProps {
    failedValidation: Validation | undefined;
}

export const ErrorMessage = ({ failedValidation }: ErrorMessageProps) => {
    if (!failedValidation?.errorMessage) {
        return null;
    }

    const Error = (failedValidation?.errorMessage as FunctionComponent) || null;
    return (
        <Text color="validationError" size="b_sm">
            <Error />
        </Text>
    );
};
