import pathfinder from '@fiverr-private/pathfinder';
import { Brief } from '../types';
import { convertBriefSectionToBriefSectionsDTO, extractAttachmentsFromBrief } from './util';
import {
    BriefSectionsDTO,
    SendBriefRequestDTO,
    EditAndSendBriefTemplateRequestDTO,
    BaseSendBriefRequestDTO,
} from './types';
import { safeRequest } from './safeRequest';

interface SendBriefParams {
    isNewBrief: boolean;
    brief: Brief;
    subcategoryId: number;
    sellerId: number;
    source: string;
    componentName: string;
}

export const sendBrief = ({
    isNewBrief,
    brief,
    subcategoryId,
    sellerId,
    source,
    componentName,
}: SendBriefParams): Promise<void> => {
    const [dataSections, attachments] = extractAttachmentsFromBrief(brief.sections);
    const sections: BriefSectionsDTO = convertBriefSectionToBriefSectionsDTO(dataSections);
    const pendingAttachmentsIds = attachments
        ?.map((attachment) => attachment.pending_attachment_id)
        .filter(Boolean) as string[];
    const dataAsDto: BaseSendBriefRequestDTO = {
        subcategory_id: subcategoryId,
        seller_id: sellerId,
        brief_title: brief.title,
        source,
        component_name: componentName,
    };
    if (isNewBrief) {
        return sendNewBrief({ ...dataAsDto, brief_content: sections, attachments_ids: pendingAttachmentsIds });
    }
    return editAndSendBriefTemplate({
        ...dataAsDto,
        override_content: sections,
        template_id: brief.id!,
        attachments_ids: pendingAttachmentsIds,
    });
};

export const sendNewBriefUrl = pathfinder('save_and_send_productized_brief');
export const editAndSendBriefTemplatesUrl = pathfinder('edit_and_send_productized_brief');

const sendNewBrief = (data: SendBriefRequestDTO): Promise<void> =>
    safeRequest<void>('post', sendNewBriefUrl, undefined, { data });

const editAndSendBriefTemplate = (data: EditAndSendBriefTemplateRequestDTO): Promise<void> =>
    safeRequest<void>('post', editAndSendBriefTemplatesUrl, undefined, { data });

export const deleteAttachments = (templateId: string, ids: string[]) =>
    safeRequest<void>(
        'delete',
        pathfinder('delete_productized_brief_attachments', { template_id: templateId }),
        undefined,
        {
            data: { attachments_ids: ids },
        }
    );
