import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { translate } from '@fiverr-private/i18n-react';
import { AiModelsCarouselWrapper } from './AiModelsCarouselWrapper';

export const AiModelBadgeTooltip = () => (
    <Container height="264px" width="670px" backgroundColor="black" padding="4" borderRadius="lg">
        <AiModelsCarouselWrapper
            title={translate('agency_page_perseus.ai_models.carousel.title')}
            description={translate('agency_page_perseus.ai_models.carousel.description')}
        />
    </Container>
);
