import { useMutation } from 'react-query';
import { logger } from '@fiverr-private/obs';
import { useBriefContext } from '../../common/BriefContext';
import { sendBrief } from '../../services';
import { deleteAttachments } from '../../services/sendBriefService';
import { SectionTypes } from '../../types';
import { excludeEmptySections } from './util';

export const useBriefActions = () => {
    const { editedBrief, subCategoryId: subcategoryId, source, componentName } = useBriefContext();

    const removeDeletedAttachments = async () => {
        const attachmentsSection = editedBrief?.sections?.find((section) => section.type === SectionTypes.attachments);
        let attachmentsToDelete = editedBrief?.deletedAttachmentIds;

        if (attachmentsSection?.attachments?.length && !attachmentsSection?.included) {
            attachmentsToDelete = attachmentsSection?.attachments?.map((attachment) => attachment.id);
        }
        if (editedBrief?.id && attachmentsToDelete) {
            try {
                await deleteAttachments(editedBrief.id, attachmentsToDelete);
            } catch (e) {
                logger.error(`Failed to delete attachment from brief ${editedBrief?.id}`);
            }
        }
    };

    const updateMutation = useMutation({
        mutationFn: async (options: { shouldCreateNew?: boolean; sellerId: number }) => {
            if (!editedBrief || subcategoryId === null) throw new Error('Invalid brief data');
            const brief = excludeEmptySections(editedBrief);
            await removeDeletedAttachments();

            const { shouldCreateNew, sellerId } = options;
            const isNewBrief = !brief.id && !shouldCreateNew;

            return sendBrief({
                isNewBrief,
                sellerId,
                brief,
                subcategoryId,
                source,
                componentName,
            });
        },
        onError: () =>
            logger.error(new Error('Failed while rendering the Brief-AI Edit-Brief and sending brief template')),
        retry: 5,
        retryDelay: 2000,
    });

    return {
        saveAndSendBrief: updateMutation.mutateAsync,
        isSendingBrief: updateMutation.isLoading,
    };
};
