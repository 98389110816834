import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Container } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';

interface EditBriefSendButtonProps {
    isLoading: boolean;
    onSubmit: () => void;
}

const EditBriefSendButton = ({ isLoading, onSubmit }: EditBriefSendButtonProps) => (
    <Container marginLeft="auto">
        <Button disabled={isLoading} onClick={onSubmit} intent="primary" variant="filled" loading={isLoading}>
            <I18n k="ai_brief.edit_brief.send" />
        </Button>
    </Container>
);

export default EditBriefSendButton;
