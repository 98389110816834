import React from 'react';
import { Text } from '@fiverr-private/typography';
import { BriefSection } from '../../../types';

export interface SectionTitleProps {
    title: string;
    isEditing?: boolean;
    addColon?: boolean;
}

export interface CustomSectionTitleComponentProps extends SectionTitleProps {
    section?: BriefSection;
}

export const SectionTitle = ({ title, isEditing, addColon }: SectionTitleProps) => (
    <Text fontWeight="bold" size="b_md" style={{ whiteSpace: 'pre-line' }}>
        {title}
        {addColon && !isEditing ? ':' : ''}
    </Text>
);
