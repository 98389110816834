import { Brief } from '../types';
import { safeRequest } from './safeRequest';
import { convertAttachmentsToSection, convertBriefSectionsDtoToBriefSections } from './util';
import { FullBriefTemplateResponseDTO } from './types';

const convertBriefTemplateResponseDtoToBriefTemplate = (
    { title, brief_content, attachments }: FullBriefTemplateResponseDTO,
    id: string
) => ({
    title,
    id,
    sections: convertBriefSectionsDtoToBriefSections(convertAttachmentsToSection(brief_content, attachments)),
});

export const getExistBriefTemplateUrl = (briefTemplateId: string) =>
    `/briefs/api/productized/template/${briefTemplateId}`;

export const getExistBriefTemplate = (briefTemplateId: string): Promise<Brief | undefined> =>
    safeRequest<FullBriefTemplateResponseDTO>('get', getExistBriefTemplateUrl(briefTemplateId)).then((res) =>
        convertBriefTemplateResponseDtoToBriefTemplate(
            res as NonNullable<FullBriefTemplateResponseDTO>,
            briefTemplateId
        )
    );
