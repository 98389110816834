import React from 'react';
import { Image } from '@fiverr-private/media';
import { Stack } from '@fiverr-private/layout_components';
import { Loader } from '@fiverr-private/feedback';
import { green_800 } from '@fiverr-private/sass/helpers/index';
import { FileAttachmentData } from '../../../../../../../types';
import { isFileLoaded, selectPreviewLink } from './utils';
import { AttachmentName } from './AttachmentName';
import { FileDisplayIcon } from './ExtensionIcon';

export interface AttachmentPreviewProps {
    attachment: FileAttachmentData;
}
export const AttachmentPreview = ({ attachment }: AttachmentPreviewProps) => {
    const isLoaded = isFileLoaded(attachment);
    const previewLink = selectPreviewLink(attachment);

    if (!isLoaded) {
        return (
            <Stack width="100%" height="100%" direction="column" alignItems="center" justifyContent="flexEnd" gap="0">
                <Loader size="lg" color={green_800} />
                <AttachmentName attachment={attachment} />
            </Stack>
        );
    }
    if (previewLink) {
        return <Image src={previewLink} alt={attachment.name} height="100%" width="100%" objectFit="fill" />;
    }

    return (
        <Stack width="100%" height="100%" direction="column" alignItems="center" justifyContent="flexEnd">
            <FileDisplayIcon extension={attachment.extension} />
            <AttachmentName attachment={attachment} />
        </Stack>
    );
};
