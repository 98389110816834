import React from 'react';
import { FullMoonStartsDetailedIcon } from '@fiverr-private/icons';
import { Text } from '@fiverr-private/typography';
import { Container, Stack } from '@fiverr-private/layout_components';

interface SubTitleWithIconProps {
    title: React.JSX.Element;
    icon?: React.ReactNode;
    classNameContainer?: string;
}

export const SubTitleWithIcon = ({ title, classNameContainer, icon }: SubTitleWithIconProps) => (
    <Stack alignItems="center" gap="4" paddingY="4" className={classNameContainer} flex="0 0 auto">
        <Container flex="0 0 auto">{icon || <FullMoonStartsDetailedIcon size={48} />}</Container>
        <Text color="bodyPrimary" fontWeight="normal" size="b_md">
            {title}
        </Text>
    </Stack>
);
