import { STEPS_IDS } from '../common/constants';

interface ClickEventProps {
    currentStepId: STEPS_IDS;
    prevStep: STEPS_IDS;
    templateId?: string;
}

export const bqEvents = {
    professional_brief_clicked_on_new_brief() {
        return {
            group: 'user_action',
            type: 'professional_brief_clicked_on_new_brief',
            action: {
                type: 'click',
            },
            page: {
                element: {
                    type: 'button',
                },
            },
        };
    },
    professional_brief_clicked_on_brief_template({ templateId }: Pick<ClickEventProps, 'templateId'>) {
        return {
            group: 'user_action',
            type: 'professional_brief_clicked_on_brief_template',
            action: {
                type: 'click',
            },
            page: {
                element: {
                    type: 'button',
                },
            },
            buyer: {
                request: {
                    template: {
                        id: templateId,
                    },
                },
            },
        };
    },
    professional_brief_exit({ currentStepId }: Pick<ClickEventProps, 'currentStepId'>) {
        return {
            group: 'user_action',
            type: 'professional_brief_exit',
            action: {
                type: 'imp',
            },
            event_source: currentStepId,
        };
    },
    professional_brief_clicked_on_send_brief() {
        return {
            group: 'user_action',
            type: 'professional_brief_clicked_on_send_brief',
            action: {
                type: 'click',
            },
            page: {
                element: {
                    type: 'button',
                },
            },
        };
    },
    professional_brief_clicked_on_generate_your_brief() {
        return {
            group: 'user_action',
            type: 'professional_brief_clicked_on_generate_your_brief',
            action: {
                type: 'click',
            },
            page: {
                element: {
                    type: 'button',
                },
            },
        };
    },
    clicked_on_create_professional_brief_button() {
        return {
            group: 'user_action',
            type: 'clicked_on_create_professional_brief_button',
            action: {
                type: 'click',
            },
            page: {
                element: {
                    type: 'button',
                },
            },
        };
    },
    professional_brief_show({ currentStepId, prevStep, templateId }: ClickEventProps) {
        return {
            type: 'professional_brief_show',
            action: {
                type: 'imp',
            },
            page: {
                element: {
                    name: currentStepId,
                },
            },
            buyer: {
                request: {
                    template: {
                        id: templateId,
                    },
                },
            },
            event_source: prevStep,
        };
    },
    contact_seller_button_shown(options: { componentName: string }) {
        return {
            type: 'contact_me_button_shown',
            action: {
                type: 'imp',
            },
            page: {
                component: {
                    name: options.componentName,
                },
            },
        };
    },
};
