import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { ChevronLeftIcon } from '@fiverr-private/icons';
import { IconButton } from '@fiverr-private/button';
import { Heading } from '@fiverr-private/typography';
import { Stepper } from '@fiverr-private/orca';
import { Stack } from '@fiverr-private/layout_components';
import { theme } from '@fiverr-private/theme';
import { useBriefContext } from '../../../common/BriefContext';
import { ModalCloseBtn } from '../ModalCloseBtn';
import { STEPS_IDS } from '../../../common/constants';

interface TitleProps {
    title?: React.ReactNode;
    goBack?: () => void;
    onClose?: () => void;
    goBackClassName?: string;
    classNameContainer: string;
}

export const Title = ({
    title = <I18n k="ai_brief.freelancer_briefing_title" />,
    classNameContainer,
    goBack,
    goBackClassName,
    onClose,
}: TitleProps) => {
    const { triggerDrawerExitOrBack } = useBriefContext();
    const { currentStepId } = Stepper.useContext();

    return (
        <Stack
            flex="0 0 auto"
            className={classNameContainer}
            alignItems="center"
            justifyContent="spaceBetween"
            paddingTop="6"
            paddingBottom="4"
            color="grey_1100"
        >
            <Stack direction="row" gap="2" alignItems="center" justifyContent="spaceBetween">
                {goBack && (
                    <IconButton
                        className={goBackClassName}
                        variant="ghost"
                        size="xs"
                        aria-label="go back"
                        onClick={goBack}
                    >
                        <ChevronLeftIcon size={16} color={theme.colors.grey_1200} />
                    </IconButton>
                )}

                <Heading color="inherit" size="h_xs">
                    {title}
                </Heading>
            </Stack>
            <ModalCloseBtn
                onClose={
                    onClose ||
                    (() => triggerDrawerExitOrBack({ type: 'exit', currentStepId: currentStepId as STEPS_IDS }))
                }
            />
        </Stack>
    );
};
