import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { BusinessInfo } from '../types';

const isUserLoggedIn = () => {
    const { userId } = getContext();

    return !!userId;
};

export const getDeleteBriefUrl = (id: string) => `/briefs/api/productized/template/${id}`;
export const getBusinessInfo = async (): Promise<BusinessInfo | null> => {
    if (!isUserLoggedIn()) {
        return null;
    }

    const { data } = await axios.get(pathfinder('get_business_info'));
    return data;
};

export const updateCompanyName = (companyName: string): Promise<void> => {
    const { userId, organizationId } = getContext();
    return axios.post(pathfinder('business_info_fill'), {
        business_info: { company_name: companyName },
        user_id: userId,
        organization_id: organizationId,
    });
};

export const deleteBrief = (briefId: string): Promise<void> => axios.delete(getDeleteBriefUrl(briefId));
