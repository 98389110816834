import React, { useRef } from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { BriefSectionContentProps, BriefSectionViewContentProps } from '../index';
import { FileAttachmentData } from '../../../../../../types';
import { UploadArea } from './UploadArea';
import { AttachmentsList } from './Attachment';
import { useAttachments } from './UploadArea/useAttachments';

const TRANSLATION_PREFIX = 'ai_brief.upload_area';

export const AttachmentsEdit = ({ section, onAttachmentsChange }: BriefSectionContentProps) => {
    const containerRef = useRef<HTMLElement>(null);

    const scrollToCenter = () => {
        containerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    };

    const onChange = (attachments: FileAttachmentData[]) => {
        scrollToCenter();
        onAttachmentsChange(attachments);
    };

    const { attachments, upsertFile, isDisabled, removeFile } = useAttachments({
        initialAttachments: section.attachments,
        onChange,
    });

    return (
        <Stack ref={containerRef} direction="column" gap="3">
            <Stack
                direction="column"
                borderColor="grey_600"
                borderStyle="solid"
                borderWidth="sm"
                backgroundColor="white"
                paddingY="6"
                alignItems="center"
                borderRadius="lg"
            >
                <Stack paddingTop="14" direction="column" alignItems="center">
                    <Text size="b_md" textAlign="center">
                        <I18n k={`${TRANSLATION_PREFIX}.title`} />
                    </Text>

                    <UploadArea onChange={upsertFile} isDisabled={isDisabled} attachments={attachments} />
                </Stack>

                <Container paddingTop="6">
                    <Text size="b_sm" color="bodySecondary" textAlign="center">
                        <I18n k={`${TRANSLATION_PREFIX}.disclaimers`} />
                    </Text>
                </Container>
            </Stack>
            {!!attachments?.length && (
                <Stack>
                    <AttachmentsList attachmentsList={attachments} onDelete={removeFile} isEditing />
                </Stack>
            )}
        </Stack>
    );
};

export const AttachmentsView = ({ section }: BriefSectionViewContentProps) => (
    <AttachmentsList attachmentsList={section.attachments} />
);
