import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { InputValidations, useInputValidations } from '../components/GenerateStep/Validation';
import { Brief, BriefSection, BusinessInfo, Seller } from '../types';
import { getBusinessInfo, updateCompanyName } from '../api';
import { BigQueryEvent, bigQueryEvent } from '../monitoring/big-query';
import { bqEvents } from '../monitoring/bqEvents';
import { COMPANY_NAME_SECTION_TITLE } from '../hooks/useBriefData';
import { briefResponseQueryKey, briefTemplatesQueryKey } from './queryClient';
import { STEPS_IDS } from './constants';
import { hasSectionChanged } from './util';

interface TriggerDrawerExitParams {
    currentStepId: STEPS_IDS;
    type: 'back' | 'exit' | null;
}
export interface BriefContextValues {
    seller: Seller | null;
    subCategoryId: number | null;
    categoryId: number | null;
    userInput: string;
    setUserInput: (userInput: string) => void;
    isBriefInEditMode: boolean;
    setIsBriefInEditMode: (isBriefInEditMode: boolean) => void;
    editedBrief: Brief | null;
    setEditedBrief: (brief: BriefContextValues['editedBrief']) => void;
    updateEditedBrief: (
        updateBrief: Partial<Brief> | null,
        updatedSectionTitle?: string,
        updateSectionValues?: Partial<BriefSection>
    ) => void;
    lastIncludedSection: string;
    setLastIncludedSection: (lastIncludedSection: string) => void;
    inputValidations: InputValidations;
    companyInfo?: BusinessInfo | null;
    source: string;
    componentName: string;
    sendBigQueryEvent: (event: BigQueryEvent) => void;
    onCancelModalSubmit: (step: STEPS_IDS, navigateById: (id: STEPS_IDS) => void) => void;
    isCancelModalOpenWithType: null | 'back' | 'exit';
    triggerDrawerExitOrBack: ({ type, currentStepId }: TriggerDrawerExitParams) => void;
    hasBriefTemplateChanged: boolean;
    onContactSellerClick?: () => void;
    shouldShowContactSellerButton: boolean;
    isBriefDrawerOpen: boolean;
    isBriefDrawerDisplayed: boolean;
    setBriefDrawerDisplayed: (value: boolean) => void;
    initialText?: string;
    allowSaveAfterEditOnly?: boolean;
}

const BriefContext = createContext<BriefContextValues>({
    subCategoryId: null,
    categoryId: null,
    userInput: '',
    setUserInput: noop,
    isBriefInEditMode: false,
    setIsBriefInEditMode: noop,
    editedBrief: {} as Brief,
    setEditedBrief: noop,
    updateEditedBrief: noop,
    lastIncludedSection: '',
    setLastIncludedSection: noop,
    inputValidations: {
        addServerErrorValidation: noop,
        isDebounceInputValid: false,
        failedValidation: undefined,
    },
    source: '',
    componentName: '',
    seller: null,
    sendBigQueryEvent: noop,
    onCancelModalSubmit: noop,
    isCancelModalOpenWithType: null,
    triggerDrawerExitOrBack: noop,
    hasBriefTemplateChanged: false,
    shouldShowContactSellerButton: true,
    isBriefDrawerOpen: false,
    isBriefDrawerDisplayed: false,
    setBriefDrawerDisplayed: noop,
});

export const useBriefContext = () => useContext(BriefContext);

export interface AppProviderProps {
    seller: BriefContextValues['seller'];
    source: BriefContextValues['source'];
    componentName: BriefContextValues['componentName'];
    onClose: () => void;
    categoryId: BriefContextValues['categoryId'];
    subCategoryId: BriefContextValues['subCategoryId'];
    children: React.ReactNode;
    onContactSellerClick?: BriefContextValues['onContactSellerClick'];
    isBriefDrawerOpen: BriefContextValues['isBriefDrawerOpen'];
    brief?: BriefContextValues['editedBrief'];
    onBriefChanged?: (brief: BriefContextValues['editedBrief']) => void;
    initialText?: BriefContextValues['initialText'];
    allowSaveAfterEditOnly?: BriefContextValues['allowSaveAfterEditOnly'];
}

export const AppProvider = ({
    seller,
    source,
    onClose,
    componentName,
    subCategoryId,
    categoryId,
    onContactSellerClick,
    children,
    isBriefDrawerOpen,
    brief,
    onBriefChanged = noop,
    initialText = '',
    allowSaveAfterEditOnly,
}: AppProviderProps) => {
    const [isCancelModalOpenWithType, setCancelModalOpenWithType] = useState<'back' | 'exit' | null>(null);
    const [userInput, setUserInput] = useState(initialText);
    const [isBriefInEditMode, setIsBriefInEditMode] = useState(false);
    const [editedBrief, setBrief] = useState<BriefContextValues['editedBrief']>(brief ?? null);
    const [lastIncludedSection, setLastIncludedSection] = useState('');
    const [isBriefDrawerDisplayed, setBriefDrawerDisplayed] = useState(isBriefDrawerOpen);

    const setEditedBrief = useCallback(
        (brief: BriefContextValues['editedBrief']) => {
            if (brief !== editedBrief) {
                setBrief(brief);
            }

            onBriefChanged(brief);
        },
        [editedBrief, onBriefChanged, setBrief]
    );

    const { data: companyInfo } = useQuery({
        queryKey: ['company_data'],
        queryFn: getBusinessInfo,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
    const queryCache = useQueryClient();
    const companyNameMutation = useMutation({
        mutationFn: updateCompanyName,
        onSuccess: () => {
            queryCache.invalidateQueries(['company_data']);
        },
    });

    const shouldShowContactSellerButton = !!onContactSellerClick;

    const isNewBrief = useMemo(() => !editedBrief?.id, [editedBrief]);

    const sendBigQueryEvent = useMemo(
        () =>
            bigQueryEvent({
                sellerId: seller?.id,
                componentName,
                source,
                categoryId,
                subCategoryId,
                isBriefInEditMode,
            }),
        [seller, source, categoryId, subCategoryId, isBriefInEditMode, componentName]
    );

    const hasBriefTemplateChanged = useMemo(() => {
        const briefResponse = queryCache.getQueryData<Brief>(briefResponseQueryKey);
        return (
            isNewBrief ||
            !!editedBrief?.sections.some((section, sectionIndex) => {
                const originalSection = briefResponse?.sections[sectionIndex] as BriefSection;

                return hasSectionChanged(originalSection, section);
            })
        );
    }, [editedBrief, isNewBrief, queryCache]);

    const closeHandler = ({ currentStepId }: { currentStepId: STEPS_IDS }) => {
        sendBigQueryEvent(bqEvents.professional_brief_exit({ currentStepId }));
        setUserInput(initialText);
        queryCache.invalidateQueries([briefTemplatesQueryKey]);
        setEditedBrief(null);
        queryCache.removeQueries(briefResponseQueryKey);
        onClose();
    };

    const onCancelModalSubmit = (step: STEPS_IDS, navigateById: (id: STEPS_IDS) => void) => {
        if (isCancelModalOpenWithType === 'back') {
            const prevScreen: STEPS_IDS = editedBrief?.id ? STEPS_IDS.home : STEPS_IDS.generate;
            setEditedBrief(null);
            queryCache.removeQueries(briefResponseQueryKey);
            navigateById(prevScreen);
        } else if (isCancelModalOpenWithType === 'exit') {
            closeHandler({ currentStepId: step });
        }
        setCancelModalOpenWithType(null);
    };

    const triggerDrawerExitOrBack = ({ type, currentStepId }: TriggerDrawerExitParams) => {
        const { home, done, generationError } = STEPS_IDS;
        if (type === 'exit') {
            if (
                [home, done, generationError].includes(currentStepId) ||
                (currentStepId === STEPS_IDS.generate && !userInput?.trim()) ||
                (currentStepId === STEPS_IDS.editBrief && !hasBriefTemplateChanged)
            ) {
                closeHandler({ currentStepId });
                return;
            }
        }
        setCancelModalOpenWithType(type);
    };

    const updateEditedBrief = (
        updateBrief: Partial<Brief> | null,
        updatedSectionTitle?: string,
        updateSectionValues?: Partial<BriefSection>
    ) => {
        const brief: Brief = {
            ...(editedBrief as Brief),
            ...updateBrief,
        };

        if (updatedSectionTitle && updateSectionValues) {
            brief.sections = brief.sections.map((oldSection) =>
                getUpdatedSection(oldSection, updatedSectionTitle, updateSectionValues)
            );
        }

        if (updatedSectionTitle === COMPANY_NAME_SECTION_TITLE && companyInfo && updateSectionValues?.content) {
            companyNameMutation.mutate(updateSectionValues.content);
        }

        setEditedBrief(brief);
    };

    const updateLastIncludedSectionIfNeeded = (
        oldSection: BriefSection,
        updateSectionValues: Partial<BriefSection>
    ) => {
        if (updateSectionValues.included && !oldSection.included) {
            setLastIncludedSection(oldSection.title);
        }
    };
    const getUpdatedSection = (
        oldSection: BriefSection,
        updatedSectionTitle: string,
        updateSectionValues: Partial<BriefSection>
    ) => {
        if (oldSection.title === updatedSectionTitle) {
            updateLastIncludedSectionIfNeeded(oldSection, updateSectionValues);
            return { ...oldSection, ...updateSectionValues };
        }
        return oldSection;
    };

    return (
        <BriefContext.Provider
            value={{
                seller,
                source,
                componentName,
                subCategoryId,
                userInput,
                setUserInput,
                categoryId,
                isBriefInEditMode,
                setIsBriefInEditMode,
                editedBrief,
                setEditedBrief,
                updateEditedBrief,
                lastIncludedSection,
                setLastIncludedSection,
                inputValidations: useInputValidations(userInput),
                companyInfo,
                sendBigQueryEvent,
                onCancelModalSubmit,
                isCancelModalOpenWithType,
                triggerDrawerExitOrBack,
                hasBriefTemplateChanged,
                onContactSellerClick,
                shouldShowContactSellerButton,
                isBriefDrawerOpen,
                isBriefDrawerDisplayed,
                setBriefDrawerDisplayed,
                initialText,
                allowSaveAfterEditOnly,
            }}
        >
            {children}
        </BriefContext.Provider>
    );
};
