import React, { useEffect } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Center, Container } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { containerPaddingXByMdBreakpoint } from '../common/containerStylePaddingX';
import { Layout } from '../common/Layout';
import { SubTitleWithIcon } from '../common/SubTitleWithIcon';
import loading_gif from '../../assets/loading.gif';
import loader from '../../assets/loader.gif';
import { useBriefContext } from '../../common/BriefContext';
import { STEPS_IDS } from '../../common/constants';

import { icon } from './styles.ve.css';

const texts = [
    <I18n key="first" k={'ai_brief.loading_step.loading_texts.first'} />,
    <I18n key="second" k={'ai_brief.loading_step.loading_texts.second'} />,
    <I18n key="third" k={'ai_brief.loading_step.loading_texts.third'} />,
];

export const Loading = () => {
    const { triggerDrawerExitOrBack } = useBriefContext();
    const [currentTextIndex, setCurrentTextIndex] = React.useState(-1);

    useEffect(() => {
        setCurrentTextIndex(-1);
        setTimeout(() => {
            for (let i = 0; i < texts.length; i++) {
                setTimeout(() => {
                    setCurrentTextIndex(i);
                }, 5000 * i);
            }
        }, 3000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout
            onClose={() => triggerDrawerExitOrBack({ type: 'exit', currentStepId: STEPS_IDS.loading })}
            goBack={() => triggerDrawerExitOrBack({ type: 'back', currentStepId: STEPS_IDS.loading })}
            containerPaddingXByBreakpoint={containerPaddingXByMdBreakpoint}
            withTitle
            withDividerUnderSubTitle={false}
            content={
                <>
                    {currentTextIndex < 0 ? (
                        <SubTitleWithIcon
                            title={<I18n k="ai_brief.loading_step.sub_title" />}
                            icon={
                                <Container
                                    style={{
                                        backgroundImage: `url(${loading_gif})`,
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                    className={icon}
                                />
                            }
                        />
                    ) : (
                        <Center height="100%" gap="5">
                            <img src={loader} />
                            <Text fontWeight="semibold" size="b_sm" textAlign="center">
                                {texts[currentTextIndex]}
                            </Text>
                        </Center>
                    )}
                </>
            }
        />
    );
};
