import React, { ChangeEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { I18n } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { Typography } from '@fiverr-private/typography';
import { Checkbox } from '@fiverr-private/forms';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Stepper } from '@fiverr-private/orca';
import { Button } from '@fiverr-private/button';
import { logger } from '@fiverr-private/obs';
import { useBriefContext } from '../../common/BriefContext';
import { containerPaddingXBySmBreakpoint } from '../common/containerStylePaddingX.ve.css';
import { briefResponseQueryKey } from '../../common/queryClient';
import { Layout } from '../common/Layout';
import { Brief, Seller } from '../../types';
import brief_sent_illustration_icon from '../../assets/brief_sent_illustration.svg';
import { useBriefActions } from '../EditBrief/useBriefActions';
import { STEPS_IDS } from '../../common/constants';
import * as mixpanelEvents from '../../monitoring/mixpanel';

const T_PREFIX = 'ai_brief.done';
interface ResponseTimeProps {
    responseTime: Seller['responseTime'];
    displayName: Seller['displayName'];
}
export const ResponseTime = ({ responseTime, displayName }: ResponseTimeProps) => {
    if (!responseTime) {
        return <I18n k={`${T_PREFIX}.content_without_response_time`} />;
    } else if (responseTime === 1) {
        return (
            <I18n
                k={`${T_PREFIX}.content_in_hour`}
                params={{ sellerName: displayName, sellerResponseTime: responseTime }}
            />
        );
    }
    return (
        <I18n
            k={`${T_PREFIX}.content_in_hours`}
            params={{ sellerName: displayName, sellerResponseTime: responseTime }}
        />
    );
};

interface DoneScreenProps {
    seller: Seller;
}
export const DoneScreen = ({ seller }: DoneScreenProps) => {
    const { triggerDrawerExitOrBack, hasBriefTemplateChanged } = useBriefContext();
    const { currentStepId } = Stepper.useContext();
    const { displayName, userName, responseTime } = seller;
    const sellerInboxHref = pathfinder('inbox_show', { username: userName });
    const { data: briefResponse } = useQuery<Brief, string>(briefResponseQueryKey, { enabled: false });
    const [shouldCreateNewBrief, setShouldCreateNewBrief] = useState(false);
    const { saveAndSendBrief, isSendingBrief } = useBriefActions();
    const isEditedBrief = !!briefResponse?.id;

    const sendBrief = () => {
        if (isEditedBrief) {
            // New brief is created at the end of update step
            // Edited brief will be sent implicitly on drawer close
            mixpanelEvents.clickOnSendBrief({ type: 'existing brief', hasBriefTemplateChanged });
            saveAndSendBrief({ sellerId: seller.id, shouldCreateNew: shouldCreateNewBrief });
        }
    };
    const onSubmit = () => {
        sendBrief();
        triggerDrawerExitOrBack({ type: 'exit', currentStepId: currentStepId as STEPS_IDS });
    };

    const onViewInInboxClick = async () => {
        try {
            await sendBrief();
            triggerDrawerExitOrBack({ type: 'exit', currentStepId: currentStepId as STEPS_IDS });
            window.open(sellerInboxHref, '_blank');
        } catch {
            logger.error('Failed to update brief');
        }
    };

    const onToggleCreateNewBrief = (event: ChangeEvent<HTMLInputElement>) =>
        setShouldCreateNewBrief(event.target.checked);

    return (
        <Layout
            withDividerUnderSubTitle
            withTitle
            title={null}
            containerPaddingXByBreakpoint={containerPaddingXBySmBreakpoint}
            onClose={isEditedBrief ? onSubmit : undefined}
            content={
                <Stack direction="column" height="100%" width="100%" alignItems="center" justifyContent="center">
                    <Container
                        flexShrink="0"
                        width={82}
                        height={115}
                        style={{
                            backgroundImage: `url(${brief_sent_illustration_icon})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    />
                    <Typography size="h_sm" marginTop="6" marginBottom="3" color="grey_1100">
                        <I18n k="ai_brief.done.brief_sent" />
                    </Typography>
                    <Typography size="b_md" color="grey_1100">
                        <ResponseTime responseTime={responseTime} displayName={displayName} />
                    </Typography>

                    {isEditedBrief && (
                        <Container marginTop="6" marginBottom="3">
                            <Checkbox checked={shouldCreateNewBrief} onChange={onToggleCreateNewBrief}>
                                <Typography size="b_md" color="grey_1100">
                                    <I18n k={`${T_PREFIX}.create_new_toggle`} />
                                </Typography>
                            </Checkbox>
                        </Container>
                    )}
                </Stack>
            }
            buttons={
                <Stack direction="column" width="100%" gap="2">
                    <Button intent="primary" variant="filled" size="md" onClick={onSubmit} disabled={isSendingBrief}>
                        <I18n k={`${T_PREFIX}.done`} />
                    </Button>
                    <Button
                        onClick={onViewInInboxClick}
                        intent="secondary"
                        variant="outline"
                        disabled={isSendingBrief}
                        loading={isSendingBrief}
                    >
                        <I18n k={`${T_PREFIX}.view_brief_in_inbox`} />
                    </Button>
                </Stack>
            }
        />
    );
};
