import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { FileAttachmentData } from '../../../../../../../types';

export interface AttachmentNameProps {
    attachment: FileAttachmentData;
    color?: 'light' | 'dark';
}
export const AttachmentName = ({ attachment, color = 'dark' }: AttachmentNameProps) => {
    const isLight = color === 'light';
    return (
        <Container paddingX="1.5" width="100%">
            <Typography
                color={isLight ? 'white' : 'grey_1100'}
                fontWeight="bold"
                textAlign="center"
                overflow="hidden"
                textOverflow="ellipsis"
                style={{ lineHeight: 'initial' }}
                whiteSpace="nowrap"
            >
                {attachment?.name}
            </Typography>
            <Typography
                color={isLight ? 'grey_200' : 'grey_700'}
                textAlign="center"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
            >
                {attachment?.fileSize}
            </Typography>
        </Container>
    );
};
