import React, { useRef } from 'react';
import { Text } from '@fiverr-private/typography';
import { Input } from '@fiverr-private/forms';
import { BriefSectionContentProps, BriefSectionViewContentProps } from '../index';

export const SHORT_INPUT_LIMIT = 100;
export const ShortTextEdit = ({ section, onChange }: BriefSectionContentProps) => {
    const onContentChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value);
    const inputRef = useRef<HTMLInputElement>();
    return (
        <Input
            ref={inputRef as React.Ref<HTMLInputElement>}
            defaultValue={section.content}
            onChange={onContentChange}
            maxLength={SHORT_INPUT_LIMIT}
            autoFocus
        />
    );
};

export const ShortTextView = ({ section }: BriefSectionViewContentProps) => (
    <Text size="b_md" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {section.content}
    </Text>
);
