import { atom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { LocalizationData } from './server/types/LocalizationData';
import { ConsultationData, SellerAiModelsData } from './server';
import { FederatedSeller } from './types';

export type PageName = 'seller_page' | 'business_seller_page' | 'agency_page' | 'unknown' | string;

export const sellerAtom = atomWithImmer<FederatedSeller>(undefined as any);
export const pageNameAtom = atom<PageName>('unknown');
export const consultationDataAtom = atom<ConsultationData | Record<string, never>>({});
export const localizationDataAtom = atom<LocalizationData | undefined>(undefined);
export const isActivationCompletedAtom = atom<boolean>(false);
export const sellerAiModelsAtom = atom<SellerAiModelsData>([]);
