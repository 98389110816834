import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Stepper } from '@fiverr-private/orca';
import { Heading, Link, Typography } from '@fiverr-private/typography';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';
import { useBriefContext } from '../../common/BriefContext';
import { STEPS_IDS } from '../../common/constants';
import { containerPaddingXBySmBreakpoint } from '../common/containerStylePaddingX.ve.css';
import { Layout } from '../common/Layout';
import BrokenBriefIcon from '../../assets/brief_broken_sent_illustration.svg';
import { responsiveDisplay } from '../EditBrief/util';

const T_PREFIX = 'ai_brief.generation_error_page';

export const GenerationErrorPage = () => {
    const { triggerDrawerExitOrBack } = useBriefContext();
    const { navigateById, currentStepId: step } = Stepper.useContext();
    const currentStepId = step as STEPS_IDS;

    const goBackToGenerateStep = () => navigateById(STEPS_IDS.generate);

    return (
        <Layout
            withDividerUnderSubTitle
            withTitle
            goBack={goBackToGenerateStep}
            containerPaddingXByBreakpoint={containerPaddingXBySmBreakpoint}
            content={
                <Stack
                    direction="column"
                    height="100%"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    marginY="8"
                    gap="0"
                >
                    <Container
                        flexShrink="0"
                        width={152}
                        height={131}
                        style={{ background: `url(${BrokenBriefIcon}) no-repeat`, backgroundSize: 'cover' }}
                    />
                    <Heading size="h_sm" marginTop="6" marginBottom="3">
                        <I18n k={`${T_PREFIX}.title`} />
                    </Heading>
                    <Typography size="b_md" color="grey_1000">
                        <I18n k={`${T_PREFIX}.content`} />
                    </Typography>
                </Stack>
            }
            buttons={
                <>
                    <Container display={responsiveDisplay('block', true)}>
                        <Link onClick={goBackToGenerateStep} fontSize="b_sm" cursor="pointer">
                            <I18n k="ai_brief.edit_brief.back" />
                        </Link>
                    </Container>
                    <Container marginLeft="auto">
                        <Button
                            intent="primary"
                            variant="filled"
                            size="md"
                            onClick={() => triggerDrawerExitOrBack({ type: 'exit', currentStepId })}
                        >
                            <I18n k={`${T_PREFIX}.close`} />
                        </Button>
                    </Container>
                </>
            }
        />
    );
};
