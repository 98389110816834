import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Container } from '@fiverr-private/layout_components';
import { Link } from '@fiverr-private/typography';
import { responsiveDisplay } from '../util';

interface EditBriefBackButtonProps {
    onClick: () => void;
}

const EditBriefBackButton = ({ onClick }: EditBriefBackButtonProps) => (
    <Container display={responsiveDisplay('block', true)}>
        <Link onClick={onClick} fontSize="b_sm" cursor="pointer">
            <I18n k="ai_brief.edit_brief.back" />
        </Link>
    </Container>
);

export default EditBriefBackButton;
