import { BriefSection } from '../../../types';
import { useUpdateSection } from './useUpdateSection';

export const useExcludeSection = () => {
    const updateSection = useUpdateSection();

    return (section: BriefSection) => {
        updateSection(section, { included: false });
        section.onHide?.(section);
    };
};
