import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Stepper } from '@fiverr-private/orca';
import { STEPS_IDS } from '../common/constants';
import { Brief } from '../types';
import { briefResponseQueryKey, briefTemplatesQueryKey } from '../common/queryClient';
import { bqEvents } from '../monitoring/bqEvents';
import { useBriefContext } from '../common/BriefContext';

export const useBriefShownEvent = (shouldRefreshData: boolean, shouldRedirectToHomePage: boolean) => {
    const { currentStepId } = Stepper.useContext();
    const { sendBigQueryEvent } = useBriefContext();
    const [lastStep, setLastStep] = useState<STEPS_IDS>();

    const { data: briefTemplates } = useQuery<Brief, string>(briefTemplatesQueryKey, { enabled: false });

    const { data: briefResponse } = useQuery<Brief, string>(briefResponseQueryKey, { enabled: false });

    useEffect(() => {
        if (lastStep && !shouldRefreshData) {
            setLastStep(undefined);
        }

        if (!shouldRefreshData || !briefTemplates) {
            return;
        }

        if (shouldRedirectToHomePage && currentStepId !== STEPS_IDS.home && !lastStep) {
            return;
        }

        sendBigQueryEvent(
            bqEvents.professional_brief_show({
                currentStepId: currentStepId as STEPS_IDS,
                prevStep: lastStep as STEPS_IDS,
                templateId: briefResponse?.id,
            })
        );
        setLastStep(currentStepId as STEPS_IDS);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepId, shouldRefreshData, briefTemplates]);
};
