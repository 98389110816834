import { useQuery } from 'react-query';
import pathfinder from '@fiverr-private/pathfinder';
import { safeRequest } from '../services/safeRequest';

const route = pathfinder('get_productized_brief_generation_example');
export interface BriefPlaceholderResponse {
    generation_example: string;
}

const getBriefGenerationExample = async ({ categoryId: category_id, subCategoryId: sub_category_id }) => {
    if (!category_id) {
        return null;
    }
    return safeRequest<BriefPlaceholderResponse>('get', route, undefined, { params: { category_id, sub_category_id } });
};

interface BriefPlaceholderParams {
    categoryId: number | null;
    subCategoryId: number | null;
}

export const useBriefPlaceholder = ({ categoryId, subCategoryId }: BriefPlaceholderParams) => {
    const query = useQuery({
        queryKey: ['briefPlaceholder', categoryId, subCategoryId],
        queryFn: () => getBriefGenerationExample({ categoryId, subCategoryId }),
        enabled: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity,
    });

    if (!query.isFetched && !query.isFetching) {
        query.refetch();
    }

    return query;
};
