import axios, { AxiosError, AxiosResponse } from 'axios';
import { stats } from '@fiverr-private/obs';
import { statsPrefix } from '../common/constants';

export const axiosInstance = axios.create();

type HttpMethod = 'get' | 'post' | 'put' | 'delete';

/**
 * Transfer url or path string to metric name - extract path, replace slashes, remove special characters
 * EX. "http://fiverr.com/api@!~/get_tasks/" -> "api.get_tasks"
 */
const getDefaultMetricName = (urlString: string): string => {
    let metricName: string;

    const format = (str): string =>
        str
            .replace(/^\/|\/$/g, '')
            .replaceAll('/', '.')
            .replace(/[^a-z0-9._-]/gi, '');

    try {
        const url = new URL(urlString);
        metricName = format(url.pathname);
    } catch (error) {
        metricName = format(urlString);
    }

    return metricName;
};

export const getBaseMetricName = (url: string) => `${statsPrefix}.${getDefaultMetricName(url)}`;

export const safeRequest = async <T>(
    method: HttpMethod,
    url: string,
    metricName?: string,
    additionalParams = {},
    timeout = 10000
): Promise<T | undefined> => {
    let data: T | undefined;
    let statusForMonitor;
    const baseMetric = metricName || getBaseMetricName(url);

    try {
        const startTime = Date.now();
        const resp: AxiosResponse<T> = await axiosInstance({ method, url, timeout, ...additionalParams });
        statusForMonitor = resp.status;
        data = resp.data;

        stats.time(baseMetric, 'response_time', startTime);
    } catch (error) {
        const { isAxiosError, response } = error as AxiosError;
        if (isAxiosError) {
            if (response?.status) {
                statusForMonitor = response.status;
            } else {
                statusForMonitor = 'timeout';
            }
        } else {
            statusForMonitor = 'compute_error';
        }
    }

    stats.count(baseMetric, `status.${statusForMonitor}`);
    return data;
};
