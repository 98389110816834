import { useQueryClient } from 'react-query';
import { Stepper } from '@fiverr-private/orca';
import { logger, stats } from '@fiverr-private/obs';
import { useBriefContext } from '../../common/BriefContext';
import { statsPrefix, STEPS_IDS } from '../../common/constants';
import { generateBrief, GetBriefResponseDTO, GetBriefResponseDTOError, getNewBriefUrl } from '../../services';
import { Brief, BriefSection, SectionTypes } from '../../types';
import { briefResponseQueryKey } from '../../common/queryClient';
import { getBaseMetricName, safeRequest } from '../../services/safeRequest';
import { convertBriefSectionsDtoToBriefSections } from '../../services/util';
import { ATTACHMENTS_SECTION_TITLE, COMPANY_NAME_SECTION_TITLE, RETRY_DELAY, TIMEOUT } from './constants';

interface UseBriefDataProps {
    onError?: (error?: Error) => void;
    addDefaultSections?: (sections: BriefSection[]) => BriefSection[];
}

export const getNewBrief = async (pollingId: string, source: string, componentName: string): Promise<Brief> => {
    const url = getNewBriefUrl(pollingId);
    const baseMetricName = getBaseMetricName('/briefs/api/ai/poll');
    const res = await safeRequest<GetBriefResponseDTO>('get', url, baseMetricName, {
        params: { source, componentName },
    });

    if (!res) {
        throw 'Empty data, try again';
    }

    if (res.error) {
        stats.count(baseMetricName, `error.${res.error}`);

        return {
            title: '',
            sections: [],
            error: res.error,
        };
    }

    const sections = convertBriefSectionsDtoToBriefSections(res.generated_content);

    return {
        title: res.title,
        sections,
    };
};

export const useBriefData = ({ onError, addDefaultSections }: UseBriefDataProps = {}) => {
    const queryClient = useQueryClient();

    const {
        subCategoryId,
        userInput,
        inputValidations: { addServerErrorValidation, failedValidation },
        companyInfo,
        source,
        componentName,
        seller,
    } = useBriefContext();
    const { navigateById } = Stepper.useContext() || {};

    const goToErrorPage = () => {
        navigateById?.(STEPS_IDS.generationError);
    };

    const handleError = (error?: Error) => {
        logger.error(new Error('Failed while rendering the Brief-AI Generate Step'), { cause: error });
        if (onError) {
            onError(error);
        } else {
            goToErrorPage();
        }
    };

    const createDefaultSections = (sections: BriefSection[] = []): BriefSection[] => {
        const updatedSections = [...sections];

        if (companyInfo?.companyName?.trim()) {
            updatedSections.unshift({
                title: COMPANY_NAME_SECTION_TITLE,
                content: companyInfo.companyName.trim(),
                type: SectionTypes.shortText,
                included: true,
            });
        }

        updatedSections.push({
            title: ATTACHMENTS_SECTION_TITLE,
            content: '',
            type: SectionTypes.attachments,
            included: false,
        });

        return updatedSections;
    };

    const getNewBriefInternal = async (pollingId: string): Promise<Brief | undefined> => {
        const { title, sections, error } = await getNewBrief(pollingId, source, componentName);
        return {
            title,
            sections: addDefaultSections ? addDefaultSections(sections) : createDefaultSections(sections),
            error,
        };
    };

    const handleGenerate = async () => {
        const briefPoolingId = await queryClient.fetchQuery<string | undefined>({
            queryFn: () =>
                generateBrief({
                    userInput,
                    subCategoryId,
                    source,
                    componentName,
                    sellerUsername: seller?.userName,
                }),
            cacheTime: 0,
            retry: 5,
        });

        if (!briefPoolingId) {
            handleError();
            return;
        }

        try {
            if (!userInput || !!failedValidation) {
                return;
            }

            navigateById?.(STEPS_IDS.loading);

            const result = await queryClient.fetchQuery<Brief>({
                queryKey: briefResponseQueryKey,
                queryFn: () => getNewBriefInternal(briefPoolingId) as Promise<Brief>,
                retry: TIMEOUT / RETRY_DELAY,
                retryDelay: RETRY_DELAY,
            });

            if (!result) {
                return goToErrorPage();
            }

            if (result?.error) {
                if (Object.values(GetBriefResponseDTOError).includes(result.error)) {
                    addServerErrorValidation(result.error, userInput);
                    return navigateById(STEPS_IDS.generate);
                }
                handleError(result.error ? new Error(result?.error) : undefined);
            }

            navigateById?.(STEPS_IDS.editBrief);
            stats.count(`${statsPrefix}.generate_brief`, `success`);
            return result;
        } catch (error) {
            return handleError(error as Error);
        }
    };

    return {
        handleGenerate,
        getNewBrief: getNewBriefInternal,
    };
};
