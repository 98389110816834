import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { isAllowedCharactersValidation } from '../../../common/util';
import { GetBriefResponseDTOError } from '../../../services';
import * as styles from './styles.ve.css';

export const MAX_PROMPT_LENGTH = 2000;
export const MIN_PROMPT_LENGTH = 20;
export const MIN_WORDS = 5;

const isPromptTooLong = (brief) => brief && brief.length > MAX_PROMPT_LENGTH;
const isPromptTooShort = (brief) => brief && brief.length < MIN_PROMPT_LENGTH;

export const isPromptWordsTooShort = (brief) => {
    if (!brief) {
        return false;
    }

    const numWords = brief.match(/\S+/g).length;

    return numWords < MIN_WORDS;
};

export const isUserInputTooShort = (userInput: string) =>
    !userInput || isPromptTooShort(userInput) || isPromptWordsTooShort(userInput);

const T_PREFIX = 'ai_brief.prompt_step.error_message';

export interface Validation {
    isValid: (userInput: string) => boolean;
    errorMessage: React.ReactNode;
}
const constantValidations: Validation[] = [
    {
        isValid: (userInput) => isAllowedCharactersValidation(userInput),
        errorMessage: () => <I18n k={`${T_PREFIX}.unsupported_characters`} />,
    },
    {
        isValid: (userInput) => !isPromptTooLong(userInput),
        errorMessage: () => null,
    },
    {
        isValid: (userInput) => !isUserInputTooShort(userInput),
        errorMessage: () => <I18n k={`${T_PREFIX}.too_short_prompt`} />,
    },
];

export interface InputValidations {
    addServerErrorValidation: (error: GetBriefResponseDTOError, errorPrompt: string) => void;
    isDebounceInputValid: boolean;
    failedValidation?: Validation;
}
export const useInputValidations = (userInput: string): InputValidations => {
    const [validations, setValidations] = useState<Validation[]>(constantValidations);
    const [isDebounceInputValid, setIsDebounceInputValid] = useState(true);
    const failedValidation = validations.find((validation) => !validation.isValid(userInput));

    const isInputValid = !failedValidation;
    useEffect(() => {
        // If the input is changed from valid to invalid and the user is in the middle of typing
        // we won't bother him with error message, hence we wrap the calling of setIsInputValid(false) with debounce.
        // But if the input is changed from invalid to valid we aren't waiting but remove the error message immediately
        // hence we not wrap the calling of setIsInputValid(true) with debounce
        let timeoutHandler: NodeJS.Timeout | null = null;
        if (isInputValid) {
            setIsDebounceInputValid(true);
        } else {
            const debounceDelay = 700;
            timeoutHandler = setTimeout(() => {
                setIsDebounceInputValid(isInputValid);
            }, debounceDelay);
        }
        return () => {
            if (timeoutHandler) {
                clearTimeout(timeoutHandler);
            }
        };
    }, [isInputValid]);

    const addServerErrorValidation = useCallback((error: GetBriefResponseDTOError, errorPrompt: string) => {
        const validation: Validation = {
            isValid: (prompt) => prompt?.trim() !== errorPrompt?.trim(),
            errorMessage: () => {
                const templates: any = {};
                if (error === GetBriefResponseDTOError.tos_violation) {
                    templates.link = (text) => (
                        <a
                            className={styles.tosLink}
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.fiverr.com/terms_of_service"
                        >
                            {text}
                        </a>
                    );
                }
                return (
                    <span>
                        <I18n k={`${T_PREFIX}.${error}`} templates={templates} />
                    </span>
                );
            },
        };
        setValidations((validations) => [...validations, validation]);
    }, []);

    return {
        addServerErrorValidation,
        failedValidation,
        isDebounceInputValid,
    };
};
