import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { I18n } from '@fiverr-private/i18n-react';
import { tokens } from '@fiverr-private/theme';
import { Text } from '@fiverr-private/typography';
import StarsIcon from '../../../assets/static_stars_icon';

const T_PREFIX = 'ai_brief.prompt_step';

export const Disclaimer = () => (
    <Container
        backgroundColor="purple_100"
        color="purple_1200"
        borderRadius="lg"
        paddingX="4"
        paddingY="3"
        display="flex"
        gap="3"
    >
        <Container height="16px" width="16px" display="inlineBlock">
            <StarsIcon fill={tokens.colors.purple_1200} />
        </Container>

        <Text size="b_sm" color="inherit">
            <I18n k={`${T_PREFIX}.disclaimer`} />
        </Text>
    </Container>
);
