import React, { useMemo, FC, PropsWithChildren, ComponentProps, useEffect } from 'react';
import { Drawer, DrawerContent } from '@fiverr-private/overlay';
import { Stepper } from '@fiverr-private/orca';
import { DrawerSizeType } from '@fiverr-private/overlay/src/components/Drawer/types';
import { useCurrentBreakpoint } from '../common/util';
import { STEPS_IDS } from '../common/constants';
import { useBriefContext } from '../common/BriefContext';
import { bqEvents } from '../monitoring/bqEvents';
import { CancellationModal } from '../components/EditBrief/CancellationModal';

interface DrawerRenderProps extends Pick<ComponentProps<typeof Drawer>, 'isOpen'> {
    sellerId: number;
}

export const DrawerRender: FC<PropsWithChildren<DrawerRenderProps>> = ({ isOpen, children, sellerId }) => {
    const {
        sendBigQueryEvent,
        isCancelModalOpenWithType,
        triggerDrawerExitOrBack,
        onCancelModalSubmit,
        isBriefDrawerOpen,
        setBriefDrawerDisplayed,
    } = useBriefContext();
    const { currentStepId: stepId, navigateById: nav } = Stepper.useContext();
    const breakpoint = useCurrentBreakpoint();
    const currentStepId = stepId as STEPS_IDS;
    const navigateById = nav as (id: STEPS_IDS) => void;

    useEffect(() => {
        if (isOpen) {
            sendBigQueryEvent(bqEvents.clicked_on_create_professional_brief_button());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, sellerId]);

    const drawerWidth = useMemo((): DrawerSizeType => {
        let size: DrawerSizeType = 'md';
        if (currentStepId === STEPS_IDS.editBrief) {
            size = 'xl';
        }
        if (breakpoint === 'sm') {
            size = 'xl';
        } else if (
            (currentStepId === STEPS_IDS.loading || currentStepId === STEPS_IDS.generate) &&
            breakpoint === 'md'
        ) {
            size = 'sm';
        }

        return size;
    }, [currentStepId, breakpoint]);

    const onAnimationEnd = () => {
        setBriefDrawerDisplayed(isBriefDrawerOpen);
    };

    return (
        <Drawer isOpen={isOpen} onClose={() => triggerDrawerExitOrBack({ type: 'exit', currentStepId })}>
            <DrawerContent size={drawerWidth} onAnimationEnd={onAnimationEnd}>
                {children}
            </DrawerContent>
            <CancellationModal
                isOpen={!!isCancelModalOpenWithType}
                onClose={() => triggerDrawerExitOrBack({ type: null, currentStepId })}
                onSubmit={() => onCancelModalSubmit(currentStepId, navigateById)}
            />
        </Drawer>
    );
};
