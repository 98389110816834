import { useMutation, useQueryClient } from 'react-query';
import { briefTemplatesQueryKey, deleteBriefResponseQueryKey } from '../../common/queryClient';
import { deleteBrief } from '../../api';

export const useBriefDelete = (briefId: string) => {
    const queryCache = useQueryClient();
    const briefDeleteMutation = useMutation({
        mutationKey: [deleteBriefResponseQueryKey, briefId],
        mutationFn: () => deleteBrief(briefId),
        onSuccess: () => queryCache.invalidateQueries([briefTemplatesQueryKey]),
    });

    return {
        deleteBrief: briefDeleteMutation.mutateAsync,
        isDeleting: briefDeleteMutation.isLoading,
    };
};
