import React from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';

const TRANSLATION_PREFIX = 'ai_brief.contact_seller';

export const Separator = () => (
    <Stack gap="2.5" alignItems="center" color="grey_900">
        <Container width="100%" height="1px" borderColor="grey_400" borderTopWidth="sm" />
        <Text color="inherit" size="b_md" fontWeight="semibold">
            <I18n k={`${TRANSLATION_PREFIX}.separator`} />
        </Text>
        <Container width="100%" height="1px" borderColor="grey_400" borderTopWidth="sm" />
    </Stack>
);
