import React, { ReactNode, useState } from 'react';
import { noop } from 'lodash';
import {
    Modal,
    ModalCloseBtn,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalTrigger,
    useModalContext,
} from '@fiverr-private/overlay';
import { Button } from '@fiverr-private/button';
import { Container } from '@fiverr-private/layout_components';
import { useKeyboardShortcuts } from '@fiverr-private/hooks';
import { logger } from '@fiverr-private/obs';

export interface ConfirmModalProps {
    title: string;
    description?: string | ReactNode;
    submitText: string;
    cancelText: string;
    onSubmit: () => void;
    children?: string | ReactNode;
    isOpen?: boolean;
    onClose?: () => void;
}
export const ConfirmModal = ({ isOpen, ...props }: ConfirmModalProps) => (
    <Modal mobileFullScreen={false} isOpen={isOpen} onClose={props.onClose}>
        <ConfirmModalContent {...props} />
    </Modal>
);

const ConfirmModalContent = ({
    title,
    description,
    cancelText,
    submitText,
    onSubmit,
    children,
    onClose = noop,
}: Omit<ConfirmModalProps, 'isOpen'>) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { close, isOpen } = useModalContext();

    const handleClose = () => {
        onClose();
        close();
    };
    const handleSubmit = async () => {
        if (!isOpen) return;
        try {
            setIsSubmitting(true);
            await onSubmit();
            setIsSubmitting(false);
            close();
        } catch (e) {
            setIsSubmitting(false);
            logger.error(`Failed to complete the action "${title}"`);
        }
    };

    useKeyboardShortcuts([
        {
            trigger: ['meta', 'enter'],
            handler: handleSubmit,
        },
        {
            trigger: ['ctrl', 'enter'],
            handler: handleSubmit,
        },
        {
            trigger: 'escape',
            handler: onClose,
        },
    ]);

    return (
        <>
            {!!children && <ModalTrigger>{children}</ModalTrigger>}
            <ModalContent>
                <ModalHeader>
                    {title}
                    <ModalCloseBtn />
                </ModalHeader>
                <Container paddingLeft="8" paddingRight="8">
                    {description}
                </Container>
                <ModalFooter>
                    <Button onClick={handleClose} variant="ghost">
                        {cancelText}
                    </Button>

                    <Button onClick={handleSubmit} colorScheme="red" loading={isSubmitting}>
                        {submitText}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </>
    );
};
