import React, { useState } from 'react';
import { Container, Stack, Center } from '@fiverr-private/layout_components';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { Text } from '@fiverr-private/typography';
import { UploadIcon } from '@fiverr-private/icons';
import FileUploader from '@fiverr-private/file_uploader';
import { FileAttachmentData } from '../../../../../../../types';
import { MAX_FILES, UPLOAD_CONFIG } from './useAttachments';

const TRANSLATION_PREFIX = 'ai_brief.upload_area';

const UploadButton = () => (
    <Center position="absolute" left={0} right={0} top={0} width="100%" margin="auto">
        <Button variant="outline" size="sm">
            <Container width="16px">
                <UploadIcon />
            </Container>
            <Text whiteSpace="nowrap" fontWeight="semibold">
                <I18n k={`${TRANSLATION_PREFIX}.button`} />
            </Text>
        </Button>
    </Center>
);

export interface UploadAreaProps {
    onChange: (attachment: FileAttachmentData) => void;
    isDisabled?: boolean;
    attachments?: FileAttachmentData[];
}

export const UploadArea = ({ onChange: _onChange, isDisabled, attachments: _attachments }: UploadAreaProps) => {
    const [attachments, setAttachments] = useState(_attachments || []);

    const onChange = (file: FileAttachmentData) => {
        _onChange(file);
        setAttachments((savedAttachments) => {
            const isFileSaved = attachments.find((attachment) => attachment.id === file.id);
            if (!isFileSaved) {
                return [...savedAttachments, file];
            }

            return savedAttachments.map((attachment) => (attachment.id === file.id ? file : attachment));
        });
    };

    return (
        <Stack direction={'column'} position="relative">
            <FileUploader
                attachments={attachments}
                onAttachmentChange={onChange}
                filesLimit={MAX_FILES}
                color={'white'}
                options={UPLOAD_CONFIG}
                isDisabled={isDisabled}
                showTitle={false}
                multiple
                icon={UploadButton}
            />
        </Stack>
    );
};
