import pathfinder from '@fiverr-private/pathfinder';
import { BriefContextValues } from '../common/BriefContext';
import { safeRequest } from './safeRequest';

export const briefUrl = pathfinder('generate_ai_brief');

interface GenerateBriefParams {
    userInput: string;
    subCategoryId: BriefContextValues['subCategoryId'];
    source: string;
    componentName: string;
    sellerUsername?: string;
}

export const generateBrief = ({
    userInput,
    subCategoryId,
    source,
    componentName,
    sellerUsername,
}: GenerateBriefParams): Promise<string | undefined> =>
    safeRequest('post', briefUrl, undefined, {
        data: {
            user_input: userInput,
            subcategory_id: subCategoryId,
            seller_username: sellerUsername,
            source,
            component_name: componentName,
        },
    });

export const getNewBriefUrl = (generation_id: string) => pathfinder('poll_ai_brief', { generation_id });
