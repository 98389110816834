import { ConnectedAttachmentData, SectionTypes } from '../types';

export enum GetBriefResponseDTOError {
    tos_violation = 'tos_violation',
    unable_to_produce_a_brief = 'unable_to_produce_a_brief',
    not_english = 'not_english',
    love_input = 'love_input',
}

export interface GetBriefResponseDTO {
    title: string;
    generated_content: BriefSectionsDTO;
    attachments?: ConnectedAttachmentData[];
    error?: GetBriefResponseDTOError;
}

export interface BriefSectionsDTO {
    [key: string]: {
        content: string;
        attachments?: ConnectedAttachmentData[];
        type?: SectionTypes;
        included: boolean;
    };
}

export interface FullBriefTemplateResponseDTO {
    title: string;
    brief_content: BriefSectionsDTO;
    attachments?: ConnectedAttachmentData[];
}

export interface BriefTemplatesResponseDTO {
    templates: ShortBriefTemplateResponseDTO[];
}

export interface ShortBriefTemplateResponseDTO {
    brief_title: string;
    template_id: string;
    updated_by_name: string;
    update_at: number;
}

export interface BaseSendBriefRequestDTO {
    brief_title: string;
    subcategory_id: number;
    seller_id: number;
    source: string;
    component_name: string;
}

export interface SendBriefRequestDTO extends BaseSendBriefRequestDTO {
    brief_content: BriefSectionsDTO;
    attachments_ids?: string[];
}

export interface EditAndSendBriefTemplateRequestDTO extends BaseSendBriefRequestDTO {
    override_content?: BriefSectionsDTO;
    template_id: string;
    attachments_ids?: string[];
}
